<template>
  <ion-page>
    <toolbar :title="toolbarTitle">
      <template #trailing v-if="isLoggedIn">
        <UserDialogButton />
      </template>
    </toolbar>

    <ion-content>
      <div class="container mt-10">
        <h3
          class="m-4 text-3xl"
          data-cy="header-impressum"
        >
          {{ t('toolbar.impressum') }}
        </h3>
        <div class="m-4">
        <h2>Firmawortlaut</h2>
        MovingLayers GmbH
        <h2>Unternehmensgegenstand</h2>
        Beratung zu und Erstellung von geographischen Informationslösungen
        <h2>UID-Nummer</h2>
        ATU 78786645
        <h2>Firmenbuchnummer und Firmenbuchgericht</h2>
        593363v Handelsgericht Wien
        <h2>Firmensitz</h2>
        Gumpendorfer Straße 67/9<br>
        1060 Wien | Austria
        <h2>Kontaktdaten</h2>
        Tel : +43 664 55 31 682<br>
        E-Mail : office@movinglayers.eu<br>
        <h2>WK-Mitgliedschaften</h2>
        Mitglied der WKÖ, WK Wien, UBIT
        <h2>Anwendbare Rechtsvorschriften</h2>
        <a href="http://www.ris.bka.gv.at/" target="_blank" rel="noopener">http://www.ris.bka.gv.at/</a>
        <a href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10007517" target="_blank" rel="noopener">Gewerbeordnung</a>
        <h2>Aufsichts-/Gewerbebehörde</h2>
        Magistratisches Bezirksamt 6. Bezirk Wien
        <h2>Online-Streitbeilegung</h2>
        Verbraucher haben die Möglichkeit, Beschwerden an die Online-Streitbeilegungsplattform der EU zu richten: <a href="http://ec.europa.eu/odr" target="_blank" rel="noopener">http://ec.europa.eu/odr</a><br>
        Sie können allfällige Beschwerde auch an die oben angegebene E-Mail-Adresse richten.
        <br><br>
        <a href="https://www.movinglayers.eu/wordpress/wp-content/uploads/2024/07/AGB-MovingLayers.pdf" target="_blank" rel="noopener">AGB-MovingLayers.pdf</a>
        <br><br>
        Unser Eintrag im Firmen A-Z der WKO: <a href="https://firmen.wko.at/movinglayers-gmbh/wien/?firmaid=ac12a72a-c780-4ac8-a1cf-6ff126c41e1c" target="_blank" rel="noopener">MovingLayers GmbH</a>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import AButton from "@/components/Base/AButton";
import { useI18n } from "vue-i18n";
import Toolbar from "@/components/Navigation/Toolbar";
import useUser from "@/composables/useUser";
import { useStore } from "@/composables/useTypedStore";

import {
  IonContent,
  IonPage,
  onIonViewWillEnter,
} from "@ionic/vue";
import { defineComponent, ref, computed } from "vue";
import UserDialogButton from "@/components/User/UserDialogButton.vue";

export default defineComponent({
  name: "ImpressumPage",
  components: {
    AButton,
    Toolbar,
    IonContent,
    IonPage,
    UserDialogButton,
  },

  setup() {
    const { t } = useI18n({ useScope: "global" });
    const user = useUser();
    const isLoggedIn = ref(false);
    const store = useStore();

    const toolbarTitle = computed(() => {
      return isLoggedIn.value ? t('toolbar.impressum') : '';
    });

    onIonViewWillEnter(() => {
      user.isLoggedIn().then((response) => {
        isLoggedIn.value = response;
        if(isLoggedIn.value){
          store.commit("app/showBottomNavigation");
        } 
      });    
    });

    return {
      t,
      isLoggedIn,
      toolbarTitle
    };
  },
});
</script>

<style scoped lang="scss">
* {
  box-sizing: border-box;
}

h1 {
  color: var(--black100);
  @media (max-width: 460px) {
    font-size: 22px;
    font-weight: bold;
    padding-left: 0;
    text-align: left;
  }
}

.container {
  padding: 0;
}

ul {
    padding-left: 25px;
    margin: 0;
}

li {
  @media (max-width: 460px) {
    font-size: .95em;
  }
}

</style>