<template>
  <div class="editPropertyModal">
    <ion-content>
      <div class="editPropertyContent container p-6">
        <div class="formHolder">
          <div class="headline3 mb-5">
            {{ t(`${organisationPrefix}createProperty.editObject`) }}
          </div>
          <div class="sectionItem mb-4">
            <div class="label">
              {{ t('createProperty.name') }}*
            </div>
            <div class="value">
              <AppInput
                v-model="body.name"
                :placeholder="t('createProperty.typeLabel')"
              />
            </div>
          </div>
          <div class="sectionItem mb-4">
            <div class="label">
              {{ t(`${organisationPrefix}createProperty.externalObjNumber`) }}
            </div>
            <div class="value">
              <AppInput
                v-model="body.externeObjektNr"
                :placeholder="t('createProperty.typeLabel')"
              />
            </div>
          </div>
          <div class="sectionItem mb-4">
            <div class="label">
              {{ t('createProperty.street') }}
            </div>
            <div class="value">
              <AppInput
                v-model="body.strasse"
                :placeholder="t('createProperty.typeLabel')"
              />
            </div>
          </div>
          <div class="sectionItem mb-4">
            <div class="label">
              {{ t('createProperty.plz') }}
            </div>
            <div class="value">
              <AppInput
                v-model="body.plz"
                :placeholder="t('createProperty.typeLabel')"
              />
            </div>
          </div>
          <div class="sectionItem mb-4">
            <div class="label">
              {{ t('createProperty.city') }}
            </div>
            <div class="value">
              <AppInput
                v-model="body.stadt"
                :placeholder="t('createProperty.typeLabel')"
              />
            </div>
          </div>
          <div class="sectionItem mb-4">
            <div class="label">
              {{ t('createProperty.bezirk') }}
            </div>
            <div class="value">
              <AppInput
                v-model="body.stadtbezirk"
                :placeholder="t('createProperty.typeLabel')"
              />
            </div>
          </div>
          <div class="sectionItem mb-4">
            <div class="label">
              {{ t(`${organisationPrefix}createProperty.buildingYear`) }}*
            </div>
            <div class="value">
              <AppInput
                v-model="body.baujahr"
                :placeholder="t('createProperty.typeLabel')"
              />
            </div>
          </div>
          <div
            v-if="isNetworkAvailable"
            class="geolocationItem mb-4"
          >
            <div class="label mb-4">
              {{ t('createProperty.geoLocation') }}*
            </div>
            <div class="value">
              <AppMap
                :identifier="'objectLocation'"
                :map-settings="currentProject.mapConfig?.createPropertyMap"
                :create-property-map="true"
                :property-location="{lat: property.geolocationLat, lon: property.geolocationLon}"
                @update:input-json="onChanged"
                @update:mapSearch="handleMapSearchResult"
                @update:clearMapSearchInputs="clearMapSearchResult"
              />
            </div>
          </div>
        </div>
        <div class="buttonsHolder">
          <AButton
            :disabled="isButtonActive ? false : true"
            :btn-primary="true"
            class="px-16 py-6 text-lg"
            @click="isButtonActive ? editProperty() : ''"
          >
            {{ t('editProperty.buttons.confirmEdit') }}
          </AButton>
          <AButton
            :btn-secondary="true"
            class="px-16 py-6 text-lg"
            @click="modalController.dismiss()"
          >
            {{ t('editProperty.buttons.cancelEdit') }}
          </AButton>
        </div>
      </div>
    </ion-content>
  </div>
</template>

<script setup lang="ts">
import AButton from '@/components/Base/AButton.vue';
import AppInput from '@/components/v2/App/AppInput.vue';
import AppMap from '@/components/v2/App/AppMap.vue';
import { useStore } from "@/composables/useTypedStore";
import Immobilie from '@/models/immobilie.model';
import User from '@/models/user';
import { IonContent, modalController } from '@ionic/vue';
import { computed, ComputedRef, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  property: {
    type: Object,
    required: true
  }
})

const store = useStore();
const { t } = useI18n({ useScope: 'global' })
const currentProject = computed(() => store.state.user.currentUserProject);
const isNetworkAvailable = computed(() => store.state.app.networkConnected)
const organisationPrefix: ComputedRef<string> = computed(() => {
  const currentUser: User | null = User.query().first();

  return currentUser ? currentUser.organisationPrefix : "";
});

const body = ref({
  name: props.property.name,
  strasse: props.property.strasse,
  plz: props.property.plz,
  stadt: props.property.stadt,
  stadtbezirk: props.property.stadtbezirk,
  externeObjektNr: props.property.externeObjektNr,
  baujahr: props.property.baujahr,
  geolocationLat: props.property.geolocationLat,
  geolocationLon: props.property.geolocationLon,
});

const isButtonActive = computed(() => {
  let value = false

  if(isNetworkAvailable.value) {
    value = buttonActiveOnline()
  } else {
    value = buttonActiveOffline()
  }

  return value
})

function buttonActiveOnline() {
  let value = false
  if(
    body.value.name &&
    body.value.baujahr &&
    body.value.geolocationLat &&
    body.value.geolocationLon
  ) {
    value = true
  } else {
    value = false
  }
  return value
}

function buttonActiveOffline() {
  let value = false
  if(
    body.value.name &&
    body.value.name
  ) {
    value = true
  } else {
    value = false
  }
  return value
}

const editProperty = async() => {
  const immobilieObject = {
    ...props.property,
    name: body.value.name,
    strasse: body.value.strasse,
    plz: body.value.plz,
    stadt: body.value.stadt,
    stadtbezirk: body.value.stadtbezirk,
    externeObjektNr: body.value.externeObjektNr,
    baujahr: body.value.baujahr,
    geolocationLat: body.value.geolocationLat,
    geolocationLon: body.value.geolocationLon
  } as Immobilie

  try {
    await Immobilie.api().put(`/immobilies/${props.property.id}?projectId=${currentProject.value.id}`, {
      save: true,
      data: immobilieObject
    })
  } catch(error: any) {
    immobilieObject.hasUnsavedChanges = true
  }

  await Immobilie.dispatch('storeMoverObject', immobilieObject );

  modalController.dismiss()
}

function onChanged(event: any) {
  if(event.features[0]?.geometry.coordinates) {
    body.value.geolocationLon = event.features[0].geometry.coordinates[0]
    body.value.geolocationLat = event.features[0].geometry.coordinates[1]
  }
}

function handleMapSearchResult(data: any) {
  body.value.strasse = data.address.road ? data.address.road : ''
  body.value.plz = data.address.postcode ? data.address.postcode : ''
  body.value.stadt = data.address.city ? data.address.city : ''
  body.value.stadtbezirk = data.address.city_district ? data.address.city_district : ''
  body.value.geolocationLat = data.lat
  body.value.geolocationLon = data.lon
}

function clearMapSearchResult() {
  body.value.strasse = ''
  body.value.plz = ''
  body.value.stadt = ''
  body.value.stadtbezirk = ''
  body.value.geolocationLat = ''
  body.value.geolocationLon
}
</script>

<style lang="scss">
.editPropertyModal {
  height: 100%;

  .editPropertyContent {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;

    .formHolder {
      max-width: 800px;
      width: 100%;
      margin: 0 auto;

      .sectionItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        .label, .value {
          width: calc(50% - 8px);
        }

        .value {
          background: var(--background100);
        }
      }

      .geolocationItem {
        display: flex;
        flex-direction: column;

        #objectLocation {
          height: 500px;
        }
      }
    }

    .buttonsHolder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 16px;

      @media (min-width: 1024px) {
        flex-direction: row;
      }
    }
  }
}
</style>