import { putBestandsaufnahme } from "@/api/ModelApi";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import { Monitoring } from "@/utilities/monitoring";
import { alertController } from "@ionic/core";
import { AlertInput } from "@ionic/vue";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "../useTypedStore";

export default function usePersonChangeUtils() {

    const store = useStore();
    const currentProject = computed(() => store.state.user.currentUserProject);
    const { t } = useI18n();

    const updateSurveyPerson = (ba: Bestandsaufnahme) => async (userId: number) => {
        try {
            const isOnline = store.getters["app/isOnline"];
            let updatedBaJson = null;

            if (isOnline) {
                const oldSurvey = await ba.toClassJson();
                const fieldsToSet = { gutachter: userId };
                updatedBaJson = await putBestandsaufnahme(ba.id, currentProject.value.id, fieldsToSet, oldSurvey);
            } else {
                updatedBaJson = await ba.toClassJson();
                updatedBaJson.gutachter = userId;
                updatedBaJson.hasUnsavedChanges = true;
            }

            await BestandsaufnahmeModel.dispatch('storeMoverSurvey', updatedBaJson );

            if (updatedBaJson && ba.id === store.state.currentHzba?.currentBa?.id) {
                updatedBaJson.isLocal = false; // legacy property, make sure it is not set to true
                const updatedBa = new Bestandsaufnahme(updatedBaJson);
                store.commit('currentHzba/setCurrentBa', updatedBa);
            }

        } catch (error: any) {
            Monitoring.chainError("Error while setting person/gutachter of Bestandsaufnahme", error);
        }
    };

    const openChangePersonAlert = async (ba: Bestandsaufnahme) => {
        try {
            const alertButtons = [
                {
                    text: t('hzba.buttons.confirm'),
                    handler: updateSurveyPerson(ba)
                },
                t('hzba.buttons.cancel')
            ];

            const users: AlertInput[] = store.state.user.organizationUsers.map((item: any) => ({
                label: item.username,
                type: 'radio',
                value: item.id,
                checked: ba?.gutachter?.id === item.id
            }));

            const alert = await alertController.create({
                header: t("hzba.updatePerson"),
                buttons: alertButtons,
                inputs: users
            });
            alert.present();
        } catch (error: any) {
            Monitoring.chainError("Error while opening change person alert", error);
        }
    };
    return {
        openChangePersonAlert
    };
}

