import useAlert from "@/composables/useAlert";
import { Monitoring } from "@/utilities/monitoring";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Share } from "@capacitor/share";
import { isPlatform } from "@ionic/vue";
import { computed, ref } from "vue";


export default function useDownloadHelper() {

    const canShareLocal = ref();

    Share.canShare().then((res: any) => {
        canShareLocal.value = res.value;
    })


    const isMobile = computed(() => {
        return (isPlatform("ios") || isPlatform("ipad") || isPlatform("android"));
    });

    const canShare = computed(() => {
        return isMobile.value && canShareLocal.value;
    })

    const isProcessing = ref(false);
    const alert = useAlert();

    const blobToBase64 = (blob: any): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function() {
                const dataUrl = reader.result;
                const base64 = dataUrl && dataUrl.toString().split(',')[1];
                if (!base64) {
                    return reject('Error: No base64 String found')
                }
                resolve(base64);
            };
            reader.readAsDataURL(blob);
        });
    };

    const shareBlob = async (blob: any, fileName: string) => {
        try {
            const base64 = await blobToBase64(blob)

            return Filesystem.writeFile({
                path: fileName,
                data: base64,
                directory: Directory.Cache,
                recursive: true, // create the directory if it does not exist
            })
                .then(() => {
                    return Filesystem.getUri({
                        directory: Directory.Cache,
                        path: fileName,
                    });
                })
                .then((uriResult) => {
                    return Share.share({
                        title: fileName,
                        text: fileName,
                        url: uriResult.uri,
                    });
                });
        } catch(err: any) {
            Monitoring.withScope((scope) => {
                scope.setContext('Download', { "File Name": fileName });
                Monitoring.chainError('Error downloading file (shareBlob)', err);
            });
        }
    }

    function downloadBlob(blob: any, filename: string) {
        // Create an invisible anchor element
        const anchor = document.createElement("a");
        anchor.style.display = "none";
        anchor.target = "_blank"
        anchor.href = window.URL.createObjectURL(blob);
        anchor.setAttribute("download", filename);
        document.body.appendChild(anchor);

        // Trigger the download by simulating click
        anchor.click();

        // Clean up
        window.URL.revokeObjectURL(anchor.href);
        document.body.removeChild(anchor);
    }

    const downloadFile = (url: string, fileName: string) => {

        isProcessing.value = true;

        fetch(url, {
            headers: new Headers({
                Origin: window.location.origin,
            }),
            mode: "cors",
        })
            .then(async (response) => {
                const blob = await response.blob();

                try {
                    if (canShare.value && isMobile.value) {
                        await shareBlob(blob, fileName)
                    } else {
                        await downloadBlob(blob, fileName)
                    }
                    isProcessing.value = false;
                } catch (err: any)  {
                    isProcessing.value = false;
                    Monitoring.withScope((scope) => {
                        scope.setContext('Download', { "File Name": fileName });
                        Monitoring.chainError('Error downloading/sharing file', err);
                    });

                    await alert.show('Ein Fehler ist aufgetreten.', err);
                }
            }).catch((err: any) => {
                isProcessing.value = false;
                Monitoring.withScope((scope) => {
                    scope.setContext('Download', { "File Name": fileName });
                    Monitoring.chainError('Error downloading/sharing file (Promise)', err);
                });

                alert.show('Ein Fehler ist aufgetreten.', err);
            });
    }

    return {
        canShare,
        downloadFile,
        isProcessing,
        isMobile
    }


}