import User from "@/models/user";
import { toastController } from "@ionic/vue";
import { computed, ComputedRef } from "vue";
import { useI18n } from "vue-i18n";

export default function useToasts(_t?: any) {
    let t: any;
    if (_t) {
        t = _t;
    } else {
       const { t: t_global } = useI18n({ useScope: 'global' });
       t = t_global;
    }

    const organisationPrefix: ComputedRef<string> = computed(() => {
        const currentUser: User | null = User.query().first();
  
        return currentUser ? currentUser.organisationPrefix : "";
      });

    async function presentToast(message: string, duration = 2000) {
        const toast = await toastController
            .create({
                message,
                duration,
                cssClass: "toastStyle"
            });

        // according to https://github.com/ionic-team/ionic-framework/issues/22704
        const active = await toastController.getTop();

        if (active) {
            await active.dismiss();
        }

        return toast.present();
    }

    function changesSavedLocally() {
        return presentToast(t('toasts.savedChangesLocally'));
    }

    function updateErrorBestandsaufnahme(id: number, title: string) {
        return presentToast(t('toasts.bestandsaufnahmeUpdatedError', { id, frage: title }), 5000);
    }

    function socketConnected() {
        return presentToast(t('toasts.socketConnected'), 8000);
    }

    function updateErrorDateToast() {
        return presentToast(t("hzba.datum.updateFailed"), 5000);
    }

    function deleteDocumentErrorToast() {
        return presentToast(t("toasts.deleteDocumentError"), 5000);
    }

    function deleteImageErrorToast() {
        return presentToast(t("toasts.deleteImageError"), 5000);
    }

    function pdfDownloadFailed() {
        return presentToast(t('toasts.pdfDownloadFailed'), 5000);
    }

    function propertyNotYetCreated() {
        return presentToast(t("toasts.objectNotYetCreated", { object: t(`${organisationPrefix.value}createSurvey.object`) }), 5000);
    }

    function surveyNotYetCreated() {
        return presentToast(t("toasts.objectNotYetCreated", { object: t(`${organisationPrefix.value}toolbar.mobile.surveyDetail`) }), 5000);
    }

    return {
        changesSavedLocally,
        updateErrorBestandsaufnahme,
        socketConnected,
        updateErrorDateToast,
        deleteDocumentErrorToast,
        deleteImageErrorToast,
        pdfDownloadFailed,
        propertyNotYetCreated,
        surveyNotYetCreated
    };
}