import { uploadFile } from "@/api/UploadApi";
import { SubmitProgressReport } from "@/composables/Bestandsaufnahme/useBestandsaufnahmeUpload";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import ErweitertesMedium from "@/models/erweitertes-medium.model";
import Immobilie from "@/models/immobilie.model";
import { Monitoring } from "@/utilities/monitoring";

export interface LangPdf {
    blob: Blob;
    lang: string;
}

export class PdfBerichtManager {

    public pdfViewers: any[] = [];
    private generatedPdfs: any[] = [];
    private onGenerationCompletedListener: any;

    private berichtsToUpload: any[] = [];
    private pdfRenderIndex = -1;


    constructor(private languages: string[]) {}


    public setupPdfViewerRef = (el: any, lang: string) => {
        if (el) {
            const pdfViewer = this.pdfViewers && this.pdfViewers.find((el: any) => el.lang === lang)

            if (pdfViewer) {
                pdfViewer.ref = el
            } else {
                this.pdfViewers.push({
                    ref: el,
                    lang: lang
                })
            }
        }
    }

    /**
     * Start generating reports and setting hzba.berichts values
     */
    public generateReports = (ba: Bestandsaufnahme) => {
        this.generatedPdfs = [];

        for (let i = 0; i < this.pdfViewers.length; i++) {
            this.berichtsToUpload.push({
                titel: {
                    de: `${ ba.nameBericht && ba.nameBericht.de ? ba.nameBericht.de : 'Heizungsbestandsaufnahme' } (${this.pdfViewers[i].lang})`,
                    en: `${ ba.nameBericht && ba.nameBericht.en ? ba.nameBericht.en : 'Heating test' } (${this.pdfViewers[i].lang})`,
                },
                kennzeichen: `hzba-${this.pdfViewers[i].lang}`,
            })
        }

        this.renderNextReport();

        return this.berichtsToUpload;
    }

    private renderNextReport = () => {
        this.pdfRenderIndex ++;

        console.log("Start to render next pdf report", this.pdfRenderIndex);

        if (this.pdfViewers.length-1 >= this.pdfRenderIndex) {
            this.pdfViewers[this.pdfRenderIndex].ref.downloadPdf();
        } else {
            Monitoring.withScope((scope: any) => {
                scope.setContext("Render Info", { "Index": this.pdfRenderIndex, "Viewers": this.pdfViewers.length });
                Monitoring.error("Error: renderNextReport in pdfBerichtManager tries to render an instance that does not exist:", this.pdfRenderIndex)
            });
        }
    }

    /**
     * Sets one of many pdf's after it is generated.
     */
    public setGeneratedPdfFile = async (blob: Blob, lang: string) => {
        console.log("setGeneratedPdfFile", lang);
        this.generatedPdfs.push({blob, lang})

        if (this.generatedPdfs.length === this.languages.length) {
            this.onGenerationCompletedListener && this.onGenerationCompletedListener(this.generatedPdfs);
        } else {
            this.renderNextReport();
        }
    }

    public setGenerationCompletedListener(listener: (generatedPdfs: LangPdf[]) => any) {
        this.onGenerationCompletedListener = listener
    }


    /**
     * Requires ba.berichts as an array with backend-synced ErweitertesMedium to attach the pdf files to these objects.
     * @param ba
     * @param immobilie
     * @param pdfs
     */
    static async uploadPdfsToErweiterteMedien(ba: Bestandsaufnahme, immobilie: Immobilie, pdfs: any[]): Promise<SubmitProgressReport> {

        const promisePdf: any[] = [];

        for (let i = 0; i < pdfs.length; i++) {
            const el = pdfs[i];
            const erweitertesMedium: ErweitertesMedium | undefined = ba.berichts.find(
                (berichts: any) => berichts.kennzeichen === `hzba-${el.lang}`,
            );

            if (!erweitertesMedium || !erweitertesMedium.id) {
                Monitoring.withScope((scope: any) => {
                    scope.setContext("Report info", { array: ba.berichts, lang: el.lang });
                    scope.setContext("Survey info", { "Survey Id": ba.id, "Property Id": immobilie.id });
                    Monitoring.error("Erweitertes Medium Id not found", ba.berichts, el.lang);
                });
                return Promise.reject({ success: false, message: 'The required ErweitertesMedium(Id) was not found during the PDF upload.'});
            }

            const fileName = `${immobilie?.strasse}_${immobilie?.plz}_${immobilie?.stadt}_bericht_${el.lang}.pdf`;

            promisePdf.push(
                uploadFile(
                    el.blob,
                    "api::erweitertes-medium.erweitertes-medium",
                    '' + erweitertesMedium.id,
                    'data',
                    fileName,
                ),
            );
        }

        try {
            await Promise.all(promisePdf)
            return { success: true }
        } catch (err: any) {
            Monitoring.withScope((scope: any) => {
                scope.setContext("Survey info", { "Survey Id": ba.id, "Property Id": immobilie.id });
                Monitoring.chainError("Error uploading pdfs", err);
            });
            return {
                success: false,
                message: 'Pdf hochladen fehlgeschlagen.'
            }
        }
    }

}