<template>
  <ion-content class="ion-padding">
    <div
      v-if="uploadStatus"
      class="px-4"
    >
      <h2
        v-if="isUploadActive"
        class="text-2xl"
      >
        {{ t("hzba.vorschau.bestandsaufnahmeHochladen") }}
      </h2>

      <!-- Preparing & Uploading -->
      <div
        v-if="uploadStatus === 'preparing' || uploadStatus === 'uploadingData'"
      >
        <p>{{ t("hzba.vorschau.bestandsaufnahmeHochladenBeschriebung") }}</p>
        <ion-progress-bar type="indeterminate" />
      </div>
      <div v-if="uploadStatus === 'uploadingImages'">
        <p>
          {{
            `${
              uploadImageStatus.currentSuccessfulUploads() ?? "-"
            }/${uploadImageStatus.currentTotalUploads() || "..."} ${t(
              "hzba.vorschau.bilderHochladeStatus"
            )}`
          }}
        </p>
        <ion-progress-bar :value="uploadImageStatus.currentUploadPercentage.value" />
      </div>

      <div v-if="uploadStatus === 'uploadingPdfs'">
        <p>{{ t("hzba.vorschau.bestandsaufnahmeHochladenPdfUpload") }}</p>
      </div>

      <div
        v-if="isUploadActive"
        class="mt-6"
      >
        <p>{{ t("hzba.vorschau.bestandsaufnahmeHochladenHinweis") }}</p>
        <div class="md:flex items-center">
          <div>
            <ion-button
              class="mt-2 normal-case"
              shape="round"
              :disabled="oneImageToUpload"
              @click="abortSync"
            >
              Pausieren
            </ion-button>
          </div>
          <div class="md:ml-2 mt-2 md:mt-0">
            <strong>
              {{ oneImageToUpload ?
                'Synchronisierung wird abgeschlossen...' :
                t("hzba.vorschau.bestandsaufnahmeHochladenNichtAbbrechen") }}
            </strong>
          </div>
        </div>
      </div>

      <!-- Success -->
      <h2
        v-if="isUploadSucceeded"
        class="text-2xl"
      >
        {{ t("hzba.vorschau.bestandsaufnahmeHochladenSucceeded") }}
      </h2>

      <div
        v-if="isUploadSucceeded"
        class="flex flex-col justify-between items-start"
        data-cy="info-uploadSuccess"
      >
        <p>
          {{
            isUploadSucceededSync
              ? t("hzba.vorschau.bestandsaufnahmeErfolgSync")
              : t("hzba.vorschau.bestandsaufnahmeErfolg")
          }}
        </p>

        <IonButton
          v-if="!isUploadSucceededSync"
          type="button"
          data-cy="button-uploadSuccess"
          fill="clear"
          @click="confirm"
        >
          {{ t("hzba.buttons.ok") }}
        </IonButton>

        <IonButton
          v-if="isUploadSucceededSync && isMaxXSmall"
          class="mt-2 normal-case"
          shape="round"
          data-cy="button-weiterbearbeiten"
          fill="solid"
          style="display: inline"
          @click="confirm"
        >
          {{ t("hzba.buttons.weiterbearbeiten") }}
        </IonButton>


        <IonButtons
          v-if="isUploadSucceededSync && !isMaxXSmall"
        >
          <IonButton
            data-cy="button-weiterbearbeiten"
            type="button"
            @click="confirm"
          >
            {{ t("hzba.buttons.weiterbearbeiten") }}
          </IonButton>
        </IonButtons>
      </div>

      <!-- Error -->
      <h2
        v-if="isUploadFailed"
        class="text-2xl"
      >
        <div v-if="uploadStatus === 'errorNoInternetConnection'">
          {{ t("hzba.vorschau.bestandsaufnahmeHochladenFailedNoConnection") }}
        </div>
        <div v-else-if="uploadStatus === 'errorBackendDidNotRespond'">
          {{ t("hzba.vorschau.errorBackendDidNotRespond") }}
        </div>
        <div v-else>
          {{ t("hzba.vorschau.bestandsaufnahmeHochladenFailed") }}
        </div>
      </h2>

      <div v-if="uploadStatus === 'errorData'">
        <p>{{ t("hzba.vorschau.bestandsaufnahmeFehler") }}</p>
        <IonButton
          type="button"
          @click="confirm"
        >
          {{ t("hzba.buttons.ok") }}
        </IonButton>
      </div>
      <div v-if="uploadStatus === 'errorImage'">
        <p>{{ t("hzba.vorschau.bestandsaufnahmeFehlerBilder") }}</p>
        <IonButton
          type="button"
          @click="confirm"
        >
          {{ t("hzba.buttons.ok") }}
        </IonButton>
      </div>
      <div
        v-if="uploadStatus === 'errorNoInternetConnection' || uploadStatus === 'errorBackendDidNotRespond'"
        data-cy="info-error-noInternet"
      >
        <p>{{ uploadStatus === 'errorBackendDidNotRespond' ? t("hzba.vorschau.errorBackendDidNotRespondDescription") : t("hzba.vorschau.keinInternetLokalGespeichert") }}</p>
        <IonButton
          type="button"
          fill="clear"
          data-cy="button-uploadFailed-ok"
          @click="confirm"
        >
          {{ t("hzba.buttons.ok") }}
        </IonButton>
      </div>
    </div>
  </ion-content>
</template>

<script lang="ts">
import AButton from "@/components/Base/AButton.vue";
import useScreenSize from "@/composables/useScreenSize";
import { useStore } from "@/composables/useTypedStore";
import useUploadImageStatus from "@/composables/useUploadImageStatus";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonProgressBar,
    modalController
} from "@ionic/vue";
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "HzbaUploadModal",
  components: {AButton, IonContent, IonProgressBar, IonButton, IonButtons },
  setup(props) {
    const store = useStore();
    const { t } = useI18n({ useScope: "global" });

    const uploadImageStatus = useUploadImageStatus();

    const ba = computed<Bestandsaufnahme | undefined>(() => {
      return store.state.currentHzba.currentBa;
    });

    const uploadStatus = computed(() => {
      return store.state.currentHzba.uploadStatus;
    });

    const isUploadActive = computed(() => {
      return (
        uploadStatus.value === "preparing" ||
        uploadStatus.value === "uploadingData" ||
        uploadStatus.value === "uploadingImages" ||
        uploadStatus.value === "uploadingPdfs" ||
        (uploadStatus.value === "success_json_images" &&
          ba.value?.status === "ABGESCHLOSSEN")
      );
    });

    const isUploadFailed = computed(() => {
      return (
        uploadStatus.value === "errorNoInternetConnection" ||
        uploadStatus.value === "errorBackendDidNotRespond" ||
        uploadStatus.value === "errorData" ||
        uploadStatus.value === "errorImage"
      );
    });

    const isUploadSucceeded = computed(() => {
      return (
        uploadStatus.value === "success_sync" ||
        uploadStatus.value === "success" ||
        (uploadStatus.value === "success_json_images" &&
          ba.value?.status !== "ABGESCHLOSSEN")
      );
    });
    const isUploadSucceededSync = computed(() => {
      return (
        (uploadStatus.value === "success_json_images" &&
          ba.value?.status !== "ABGESCHLOSSEN") ||
        uploadStatus.value === "success_sync"
      );
    });

    const confirm = () => {
      store.dispatch("currentHzba/confirmSuccessfullUpload");
      // popoverController.dismiss();
      modalController.dismiss();
    };

    const abortSync = async () => {
      await modalController.dismiss();
      store.commit("currentHzba/setPleasePauseSync", true)
    }

    const oneImageToUpload = computed(() => {
      const totalUploads = uploadImageStatus.currentTotalUploads()
      const succesfulUploads = uploadImageStatus.currentSuccessfulUploads()
      return totalUploads - succesfulUploads <= 1 && totalUploads > 0;
    })

    const { isMaxXSmall } = useScreenSize();

    return {
      uploadImageStatus,
      uploadStatus,
      ba,
      abortSync,
      confirm,
      isUploadActive,
      isUploadFailed,
      isUploadSucceededSync,
      isUploadSucceeded,
      oneImageToUpload,
      t,
      isMaxXSmall
    };
  },
});
</script>

<style lang="scss">
//.small-modal {
//  .modal-wrapper {
//      height: 200px;
//
//  }
//}

ion-modal.small-modal {
  --width: 80%;
  --height: 45%;
  --border-radius: 5px;
  justify-content: center;



  @media (max-width: 728px) {
    --height: 60%;
  }
}
</style>
