<template>
  <pdf-a4-page no-margin :pdf-key="lang">
    <div class="report-background w-screen h-screen">
      <page-header :ba="ba" :immobilie="immobilie" :Title="'Fotos'"/>
      <div class="flex flex-col justify-between">
        <span class="pageHeader flex justify-between">{{ header }}</span>
        <hr class="trenner-m"/>
        <div class="grid grid-cols-2 gap-4 mt-4 content w-100">
          <img v-if="getPhotoUrl(sortedImages[0])" :src="getPhotoUrl(sortedImages[0])" alt="Image 1" class="w-full h-auto bg-white border">          
          <img v-if="getPhotoUrl(sortedImages[2])" :src="getPhotoUrl(sortedImages[2])" alt="Image 1" class="w-full h-auto bg-white border">          
          <img v-if="getPhotoUrl(sortedImages[1])" :src="getPhotoUrl(sortedImages[1])" alt="Image 1" class="w-full h-auto bg-white border">          
          <img v-if="getPhotoUrl(sortedImages[3])" :src="getPhotoUrl(sortedImages[3])" alt="Image 1" class="w-full h-auto bg-white border">          
        </div>
      </div>
      <page-bottom />
    </div>
  </pdf-a4-page>
  <pdf-a4-page no-margin :pdf-key="lang" v-if="sortedImages[4] != null">
    <div class="report-background w-screen h-screen">
      <page-header :ba="ba" :immobilie="immobilie" :Title="'Fotos'"/>
      <div class="flex flex-col justify-between">
        <span class="pageHeader flex justify-between">{{ header }}</span>
        <hr class="trenner-m"/>
        <div class="grid grid-cols-2 gap-4 mt-4 content w-100">
          <img v-if="getPhotoUrl(sortedImages[4])" :src="getPhotoUrl(sortedImages[4])" alt="Image 1" class="w-full h-auto bg-white border">          
        </div>
      </div>
      <page-bottom />
    </div>
  </pdf-a4-page>
</template>

<script lang="ts">
  import PdfA4Page from '../Components/PdfA4Page.vue';
  import { defineComponent, PropType } from "vue";
  import { getPhotoUrl } from "@/utilities/get-media-url";
  import PageHeader from './PageHeader.vue';
  import PageBottom from './PageBottom.vue';
  import Immobilie from '@/models/immobilie.model';
  import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
  export default defineComponent({
    name: 'PdfNewVationTemplate',
    components: {
      PdfA4Page,
      defineComponent,
      PageHeader,
      PageBottom,
      Immobilie,
      Bestandsaufnahme
    },
    props: {
      lang: {
        type: String,
        default: 'de',
      },
      header: {
        type: String,
        required: true
      },
      imageObjects: {
        type: Array,
        required: true,
      },
      ba: {
        type: Object as PropType<Bestandsaufnahme>,
        required: true,
      },
      immobilie: {
        type: Object as PropType<Immobilie>,
        required: true,
      },
    },
    setup(props) {
      const sortedImages = props.imageObjects.sort((a:any, b:any) => b.height - a.height);
      return {
        getPhotoUrl,
        sortedImages
      };
    },
    data() {
      return {
        index: 0,
      };
  }
})
</script>

<style scoped lang="scss">
  * {
    color: black;
    background-color: white;
    font-size: 12px;
  }
  .pdf-content {
    width: 800px;
    margin: 0 auto;
    color: var(--white100);
    height: 1100px;
  }
  .report-background {
    position: absolute;
    top: 50px;
    left: 50px;
    width: 700px;
    height: 1050px;
    color: var(--black100);
    margin-bottom: -20px;
  }
  .pageHeader {
    font-weight: bold;
    font-size: 20px !important;;
  }
  .midHeader {
    font-weight: bold;
    font-size: 15px !important;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 5px;
  }
  .smallHeader {
    font-weight: bold;
    font-size: 12px !important;;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 10px;
  }
  .content {
    height: 830px;
  }
  .stackpanel {
    display: flex;
    flex-direction: row; 
  }
  .title-container {
    width: 40%;
  }
  .textBlock {
    padding-right: -20px;
    width: 450px;
  }
  span {
    padding-left: 10px;
  }
  img {
    border-radius: 5px;
    max-height: 420px;
  }
  .trenner {
    position: sticky;
    border: none;
    height: 1px;
    color: black;
    background: black;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 5px;
    margin-right: 5px;
    width: 690px;
  }
  .trenner-l {
    @extend .trenner;
    height: 2px !important;
    margin-left: -20px !important;
    margin-right: -20px !important;  
    width: 740px;
  }
  .trenner-m {
    @extend .trenner;
    height: 1.5px !important;
    margin-left: -10px !important;
    margin-right: -10px !important;
    margin-top: 5px;
    width: 720px;
  }
  .icon {
    margin-right: 20px;
    display: flex;
    padding-top: -20px;
  }
  .tdh{
    padding-left: 10px;
    padding-right: 10px; 
    font-size: 12px;
    text-align: center;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    margin-right: 50px
  }
  td {
    @extend .tdh;
    border-bottom: 0.5px solid black;
    padding-bottom: 4px;
    padding-top: 4px;
  }
  th {
    @extend .tdh;
    border-bottom: 1px solid black;
    padding-bottom: 4px;
    padding-top: 4px;

  }
  tr:last-child td {
    border-bottom: none;
    padding-bottom: 4px;
    padding-top: 4px;
  }
</style>