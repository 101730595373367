import HzbasPage from "@/pages/hzbas.vue";
import CreateProperty from '@/pages/property/CreateProperty.vue';
import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import Demo from '../pages/demo.vue';
import Login from '../pages/login.vue';
import Logout from '../pages/logout.vue';
import Settings from '../pages/settings.vue';
import Signup from '../pages/signup.vue';

import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import ProjectSelection from '@/pages/ProjectSelection.vue';
import WaitingRoom from "@/pages/WaitingRoom.vue";
import HelpPage from "@/pages/faq.vue";
import ImpressumPage from "@/pages/impressum.vue";
import LibrariesPage from "@/pages/libraries.vue";
import PrivacyPage from "@/pages/privacy.vue";
import LogsPage from "@/pages/logs.vue";
import PropertiesPage from "@/pages/properties.vue";
import { removeCache } from "@/store/storage/ionicStorage";
import { store } from '@/store/store';
import { initHandlers } from '@/utilities/initHandler';
import hzbaRouter from './hzba/index';
import propertyRouter from './property/index';
import { isFullNonDecimalNumber } from "@/utilities/offlineProperties";
import useToasts from "@/composables/useToasts";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: "/properties/map"
    },
    {
        path: '/bas',
        name: 'bas',
        component: HzbasPage,
        meta: { requiresLoggedIn: true }
    },
    ...hzbaRouter,
    ...propertyRouter,
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { requiresLoggedOut: true }
    },
    {
        path: '/signup',
        name: 'signup',
        component: Signup,
        meta: { requiresLoggedOut: true}
    },
    {
        path: '/waiting-room',
        name: 'waitingRoom',
        component: WaitingRoom,
        meta: { requiresLoggedIn: true}
    },
    {
        path: '/project-selection',
        name: 'ProjectSelection',
        component: ProjectSelection,
        meta: { requiresLoggedIn: true}
    },
    {

        path: '/properties',
        name: 'propertiesList',
        component: PropertiesPage,
        meta: { requiresLoggedIn: true },
        children: [
            {
                path: 'map',
                name: 'propertiesMap',
                component: PropertiesPage,
                meta: { requiresLoggedIn: true }
            }
        ]
    },
    {
        path: '/create-property',
        name: 'createProperty',
        component: CreateProperty,
    },
    {
        path: '/demo',
        name: 'demo',
        component: Demo,
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: { requiresLoggedIn: true}
    },
    {
        path: '/help',
        name: 'help',
        component: HelpPage,
        meta: { requiresLoggedIn: true}
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: PrivacyPage
    },
    {
        path: '/impressum',
        name: 'impressum',
        component: ImpressumPage
    },
    {
        path: '/libraries',
        name: 'libraries',
        component: LibrariesPage,
        meta: { requiresLoggedIn: true}
    },    
    {
        path: '/logout',
        name: 'logout',
        component: Logout,
        meta: { requiresLoggedIn: true}
    },
    {
        path: '/logs',
        name: 'logs',
        component: LogsPage,
        meta: { requiresLoggedIn: true}
    },
];


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

// if property or survey is offline created, it gets a decimal number between 0 and 1 as id
// if online when the route changes, redirect to the list-view, because the decimal id is not handled well by the backend
const checkIds = (to: RouteLocationNormalized, networkConnected: boolean) => {
    if (to.params.baId && networkConnected) {
        if (!isFullNonDecimalNumber(to.params.baId)) {
            return { name: "bas" };
        }
    } else if (to.params.propertyId && networkConnected) {
        if (!isFullNonDecimalNumber(to.params.propertyId)) {
            return { name: "propertiesList" };
        }
    }
}


router.beforeResolve(async (to, from, next) => {
    const user = useUser();
    const uStore = useStore();
    const toasts = useToasts(uStore.state.app.t);
    const loggedIn = await user.isLoggedIn();

    if (!loggedIn) {
        await removeCache("cachedUserProject")
    }
    await initHandlers( uStore )

    const wrongIdRedirect = checkIds(to, store.state.app.networkConnected);
    if (wrongIdRedirect) {
        store.dispatch("app/queueOfflineDataHandling");
        next(wrongIdRedirect);
        if(to.params.baId) {
            toasts.surveyNotYetCreated();
        } else {
            toasts.propertyNotYetCreated();
        }
    }

    if(to.params.propertyId) {
        store.dispatch("app/setCurrentPropertyId", to.params.propertyId);
    }

    const userRole = 'gutachter'; // TODO extra field for user role

    const routeMeta = ((to.matched && to.matched.length > 0 && to.matched[0].meta) || {}) as any;

    store.commit('app/setRecentRoute', from);
    store.commit('app/setCurrentRoute', to);

    const requiresAuthorizedUserGroup = routeMeta.authorizedUserGroups;
    const isAuthorizedUserGroup =
        loggedIn && (!routeMeta.authorizedUserGroups || routeMeta.authorizedUserGroups.find((el: string) => el === userRole));

    if (user?.user?.value?.role?.id === 4 && to.fullPath !== '/waiting-room') {
        next('/waiting-room')
    }

    if ((routeMeta.requiresLoggedIn || routeMeta.authorizedUserGroups) && !loggedIn) {
        next('/login')
    } else if ((routeMeta.requiresLoggedOut && loggedIn) || (!isAuthorizedUserGroup && requiresAuthorizedUserGroup)) {
        next('/')
    } else {
        next()
    }
});

export default router