<template>
  <div class="map-overlay">
    <div class="relative flex items-center justify-center py-5">
      <ion-button 
        v-if="geometryType"
        fill="clear"
        class="absolute left-2"
        @click="goBack"
      >
        <ion-icon
          slot="icon-only"
          size="large"
          :icon="arrowBack"
        />
      </ion-button>
      <div class="headline3">
        {{ titleText }}
      </div>
      <div
        class="absolute right-2"
        @click="closeMenu"
      >
        <ion-icon
          size="large"
          class="closeIcon"
          :icon="closeCircleOutline"
        />
      </div>
    </div>
    <div
      v-if="!geometryType"
      class="flex justify-around pb-5"
    >
      <ion-button
        fill="clear"
        @click="setGeometryType('point')"
      >
        <ion-icon
          slot="icon-only"
          size="large"
          :icon="locationOutline"
        />
      </ion-button>
      <ion-button
        fill="clear"
        @click="setGeometryType('line')"
      >
        <ion-icon
          slot="icon-only"
          size="large"
          :icon="analyticsOutline"
        />
      </ion-button>
      <ion-button
        fill="clear"
        @click="setGeometryType('polygon')"
      >
        <ion-icon
          slot="icon-only"
          size="large"
          :icon="tabletLandscapeOutline"
        />
      </ion-button>
    </div>
    <div
      v-if="geometryType"
      class="max-h-[20vh] pb-5"
    >
      <ion-grid>
        <ion-row>
          <ion-col
            size="4"
            class="flex items-center justify-center flex-col"
          >
            <AButton
              class="mb-5 w-full max-w-[240px] sm:w-60  box-border text-center"
              :btn-primary="true"
              :disabled="isRecordDisabled"
              @click="recordGeometry"
            >
              <ion-label>{{ t('hzba.buttons.recordPoint') }}</ion-label> 
            </AButton>
            <AButton
              class="mb-5 w-full max-w-[240px] sm:w-60 box-border text-center"
              :btn-primary="true"
              :disabled="isCompleteDisabled"
              @click="finishRecording"
            >
              <ion-label>{{ t('hzba.buttons.abschliessen') }}</ion-label> 
            </AButton>
          </ion-col>
          <ion-col
            size="8"
            class="pr-4"
          >
            <ion-list class="coordList">
              <ion-item
                v-for="(coordinate, index) in coordinatesArray"
                :key="index"
                class="coordItem"
              >
                <ion-label v-if="typeof coordinate[0] === 'number' && typeof coordinate[1] === 'number' && typeof coordinate[1] === 'number'">
                  {{ coordinate[1]?.toFixed(6) }}, {{ coordinate[0]?.toFixed(6) }}  <span v-if="coordinate[2]"> | {{ coordinate[2]?.toFixed(6) }} m</span>
                </ion-label>
                <ion-button
                  fill="clear"
                  @click="deletePosition(index)"
                >
                  <ion-icon
                    slot="icon-only"
                    size="medium"
                    :icon="closeCircleOutline"
                  />
                </ion-button>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</template>
<script setup lang="ts">
import AButton from "@/components/Base/AButton.vue";
import { useStore } from "@/composables/useTypedStore";
import { generateUUID } from "@ionic/cli/lib/utils/uuid";
import { IonButton, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonRow } from "@ionic/vue";
import { analyticsOutline, arrowBack, closeCircleOutline, locationOutline, tabletLandscapeOutline } from 'ionicons/icons';
import { computed, ComputedRef, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
    currentPosition: {
        type: Object,
        required: true
    }
});
const emit = defineEmits([
    "recordedGeometry",
    "deleteGeometry"
]);
type GeometryType = "point" | "line" | "polygon" | "";

const store = useStore();
const { t } = useI18n({ useScope: 'global' });
const geometryType: Ref<GeometryType> = ref("");
const recordedFeature: Ref<any> = ref({
    type: "Feature",
    geometry: {
        type: "MultiPoint",
        coordinates: [],
    },
    properties: {
        isRecordFeature: true,
        isRecorded: false,
    },
    id: null
});

const titleText = computed(() => {
    if (geometryType.value === "point") {
        return t('hzba.record.recordPoint');
    } else if (geometryType.value === "line") {
        return t('hzba.record.recordLine');
    } else if (geometryType.value === "polygon") {
        return t('hzba.record.recordPolygon');
    } else {
        return t('hzba.record.selectGeometry');
    }
});

const coordinatesArray: ComputedRef<any[]> = computed(() => {
    return recordedFeature.value.geometry.type === "Polygon" ? recordedFeature.value.geometry.coordinates[0] : recordedFeature.value.geometry.coordinates;
});

function resetRecordedFeature(): void {
    recordedFeature.value = {
        type: "Feature",
        geometry: {
            type: "MultiPoint",
            coordinates: [],
        },
        properties: {
            isRecordFeature: true,
            isRecorded: false,
        },
        id: null
    };
}

function setGeometryType(type: GeometryType) {
    recordedFeature.value.id = generateUUID();
    recordedFeature.value.geometry.coordinates = [];
    geometryType.value = type;
}

const isRecordDisabled: ComputedRef<boolean> = computed(() => {
    if (geometryType.value === "point") {
        return recordedFeature.value.geometry.coordinates.length > 0;
    } else {
        return false;
    }
});

const isCompleteDisabled: ComputedRef<boolean> = computed(() => {
    if ( geometryType.value === "point" ){
        return recordedFeature.value.geometry.coordinates.length < 1;
    } else if (geometryType.value === "line") {
        return recordedFeature.value.geometry.coordinates.length < 2;
    } else if ( geometryType.value === "polygon" ) {
        return recordedFeature.value.geometry.coordinates.length < 3;
    } else {
        return true;
    }
});

// // HELPERS FOR TESTING
// let angle = 0;
// const radius = 0.001; // Adjust the radius to control the size of the circle
// const maxSteps = 36; // Number of points to simulate (higher for smoother circle)
// const center = { lon: props.currentPosition.lon, lat: props.currentPosition.lat };

function recordGeometry(): void {
    // // HELPERS FOR TESTING
    // const lon = center.lon + radius * Math.cos(angle);
    // const lat = center.lat + radius * Math.sin(angle);
    // const alt = props.currentPosition.altitude;
    // //Increment the angle to create the next point around the circle
    // angle += (2 * Math.PI) / maxSteps;
    const lon = props.currentPosition.lon;
    const lat = props.currentPosition.lat;
    const alt = props.currentPosition.altitude;
    recordedFeature.value.geometry.coordinates.push([lon, lat, alt]);
    emit("recordedGeometry", { isFinal: false, feature: convertFeature() });
}


function finishRecording(): void {
    if (!recordedFeature.value.geometry.coordinates.length || !geometryType.value) {
        return;
    }
    recordedFeature.value.properties.isRecorded = true;
    emit("recordedGeometry", { isFinal: true, feature: convertFeature() });
}

function closeMenu(): void {
    emit("deleteGeometry", recordedFeature.value.id);
    resetRecordedFeature();
    
    store.dispatch("app/setRecordGeometryOpen", false);
}

function goBack(): void {
    setGeometryType("");
}

function deletePosition( index: number ): void {
    recordedFeature.value.geometry.coordinates.splice(index, 1);
    if ( recordedFeature.value.geometry.coordinates.length > 0) {
        emit("recordedGeometry", { isFinal: false, feature: convertFeature() });
    } else {
        emit("deleteGeometry", recordedFeature.value.id);
    }
    
}

function convertFeature(): any {
    const multiPointFeature = recordedFeature.value;

    if (!multiPointFeature || multiPointFeature.geometry.type !== "MultiPoint") {
        return null;
    }

    const coordinates = [...multiPointFeature.geometry.coordinates]; // Clone coordinates for immutability

    if (coordinates.length === 0) {
        return null;
    }

    const converted = {
        type: "Feature",
        properties: { ...multiPointFeature.properties }, // Clone properties
        geometry: {},
        id: multiPointFeature.id,
    };

    // Handle single coordinate: Always a Point
    if (coordinates.length === 1) {
        converted.geometry = {
            type: "Point",
            coordinates: coordinates[0],
        };
    }
    // Convert to Point
    else if (geometryType.value === "point") {
        converted.geometry = {
            type: "Point",
            coordinates: coordinates[0], // Use the first coordinate
        };
    }
    // Convert to LineString
    else if (geometryType.value === "line" || (geometryType.value === "polygon" && coordinates.length === 2)) {
        converted.geometry = {
            type: "LineString",
            coordinates: coordinates,
        };
    }
    // Convert to Polygon
    else if (geometryType.value === "polygon" && coordinates.length >= 3) {
        converted.geometry = {
            type: "Polygon",
            coordinates: [
                [...coordinates, coordinates[0]], // Add closing coordinate
            ],
        };
    } else {
        return null; // Unsupported case
    }

    return converted;
}
</script>
<style scoped lang="scss">
.map-overlay {
  position: absolute;
  z-index: 4;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 1.0);
  border-top: 1px solid var(--grey300);
  max-height: 500px;

  .maplibregl-user-location-dot, .mapboxgl-user-location-accuracy-circle {
    z-index: 0;
    background-color: black;
    }
    .mapboxgl-user-location-dot, .maplibregl-user-location-dot {
        z-index: 1;
        background-color: #f21d1d;
    }
}

.coordList {
    max-height: 30vh;
    overflow: scroll;
}

.closeIcon {
    font-size: 20px;
    cursor: pointer;
}
.coordItem {
    --padding-end: 0px;
    --inner-padding-end: 0px;
}

</style>