import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "mt-4 flex-1" }
const _hoisted_2 = { class: "md:flex" }
const _hoisted_3 = { class: "flex-1 text-left" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  "ion-text": "",
  class: "mt-0 mb-0",
  color: "primary"
}
const _hoisted_6 = { class: "mb-0 flex items-center" }
const _hoisted_7 = { class: "mt-2 mb-0 flex items-center" }
const _hoisted_8 = {
  key: 0,
  class: "flex flex-row justify-between items-center"
}
const _hoisted_9 = { class: "text-lg my-4" }
const _hoisted_10 = ["title"]
const _hoisted_11 = {
  key: 1,
  class: "md:justify-between m-4 mt-4"
}
const _hoisted_12 = { class: "flex items-end flex-wrap gap-y-4 gap-x-2" }
const _hoisted_13 = {
  key: 0,
  class: "mr-2"
}
const _hoisted_14 = {
  key: 1,
  class: "mr-2"
}
const _hoisted_15 = { key: 2 }
const _hoisted_16 = { class: "downloadPart my-4" }
const _hoisted_17 = { class: "text-left mt-2 sm:mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_IonIcon = _resolveComponent("IonIcon")!
  const _component_date_change = _resolveComponent("date-change")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ProfileIcon = _resolveComponent("ProfileIcon")!
  const _component_PdfChip = _resolveComponent("PdfChip")!
  const _component_HzbaButton = _resolveComponent("HzbaButton")!
  const _component_DownloadSurveyButton = _resolveComponent("DownloadSurveyButton")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_ctx.ba && _ctx.immobilie)
    ? (_openBlock(), _createBlock(_component_ion_card, {
        key: 0,
        class: _normalizeClass(["hzbaCard m-0 rounded-sm bg-white acon-card-shadow", [`${!_ctx.isImmobilienverwalter() ? 'cursor-pointer' : ''}`,
             _ctx.withAddButton ? 'flex flex-row pr-4' : 'px-4 flex flex-col justify-between']]),
        onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.goToBa($event)))
      }, {
        default: _withCtx(() => [
          (_ctx.withAddButton)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "h-auto pl-2 pr-2 mr-1 shadow-lg rounded-lg flex",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('create-survey')), ["stop"]))
              }, [
                _createVNode(_component_ion_icon, {
                  slot: "icon-only",
                  class: "h-12 w-12 my-auto",
                  icon: _ctx.addCircle
                }, null, 8, ["icon"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass({ 'ml-2' : _ctx.withAddButton })
          }, [
            _createElementVNode("div", null, [
              (_ctx.uiMode === 'normal')
                ? (_openBlock(), _createBlock(_component_a_image, {
                    key: 0,
                    "image-class": 'w-full h-44 object-cover md:block',
                    src: _ctx.immobilie && _ctx.getPhotoUrl(_ctx.immobilie.vorschaubild, { thumbnail: true })
                  }, null, 8, ["src"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    (_ctx.uiMode === 'normal')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createElementVNode("div", null, _toDisplayString(_ctx.ba.getName && _ctx.ba.getName()), 1),
                          _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.immobilie && _ctx.immobilie.externeObjektNr) + " (" + _toDisplayString(_ctx.moment(_ctx.ba.begehungsdatum).format("YYYY")) + ") ", 1),
                          _createElementVNode("p", _hoisted_6, [
                            _createVNode(_component_IonIcon, {
                              icon: _ctx.locationOutline,
                              class: "mr-1"
                            }, null, 8, ["icon"]),
                            _createTextVNode(" " + _toDisplayString(_ctx.immobilie && ( _ctx.immobilie.strasse ? _ctx.immobilie.strasse + "," : "" )) + " " + _toDisplayString(_ctx.immobilie && _ctx.immobilie.plz) + " " + _toDisplayString(_ctx.immobilie && _ctx.immobilie.stadt), 1)
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", null, [
                      _createVNode(_component_date_change, {
                        modelValue: _ctx.changedBegehungsdate,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.changedBegehungsdate) = $event)),
                        format: "icon",
                        title: _ctx.$t(`${_ctx.organisationPrefix}hzba.datum.begehung`),
                        loading: _ctx.isPerformingDatabaseUpdate,
                        "date-icon": _ctx.calendarClearOutline,
                        readonly: !_ctx.canUpdateBegehungsDate,
                        onChanged: _ctx.saveBegehungsDate
                      }, null, 8, ["modelValue", "title", "loading", "date-icon", "readonly", "onChanged"]),
                      _createVNode(_component_date_change, {
                        modelValue: _ctx.changedSichtungsdate,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.changedSichtungsdate) = $event)),
                        format: "icon",
                        title: _ctx.$t(`${_ctx.organisationPrefix}hzba.datum.sichtung`),
                        loading: _ctx.isPerformingDatabaseUpdate,
                        "date-icon": _ctx.calendarOutline,
                        readonly: !_ctx.canUpdateSichtungsDate,
                        onChanged: _ctx.saveSichtungsDate
                      }, null, 8, ["modelValue", "title", "loading", "date-icon", "readonly", "onChanged"]),
                      _createElementVNode("p", _hoisted_7, [
                        _createVNode(_component_IonIcon, {
                          icon: _ctx.syncOutline,
                          class: "mr-2",
                          title: _ctx.$t('hzba.zuletztAktualisiertAm')
                        }, null, 8, ["icon", "title"]),
                        _createTextVNode(" " + _toDisplayString(_ctx.moment(_ctx.ba.updatedAt).format("DD.MM.YYYY")) + " ", 1),
                        _createVNode(_component_IonIcon, {
                          icon: _ctx.timeOutline,
                          class: "ml-3 mr-1"
                        }, null, 8, ["icon"]),
                        _createTextVNode(" " + _toDisplayString(_ctx.moment(_ctx.ba.updatedAt).format("HH:mm")), 1)
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            (_ctx.uiMode === 'compact')
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_ion_button, {
                      class: "open-survey",
                      onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.goToBa($event)), ["stop"]))
                    }, {
                      default: _withCtx(() => [
                        (!_ctx.isMaxSmall)
                          ? (_openBlock(), _createBlock(_component_ion_label, {
                              key: 0,
                              class: "px-2"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("immobilie.toOpen")), 1)
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_ion_icon, {
                              key: 1,
                              class: "w-5 h-5",
                              icon: _ctx.openOutline
                            }, null, 8, ["icon"]))
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", {
                    class: _normalizeClass(["flex flex-row items-center justify-end mini", _ctx.isMaxSmall ? 'gap-2' : 'gap-4'])
                  }, [
                    (_ctx.recommendedBericht(_ctx.ba))
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          "btn-secondary": true,
                          class: "h-8 text-base cursor-pointer",
                          shape: "round",
                          "data-cy": "button-berichtAnzeigen",
                          onClick: _cache[4] || (_cache[4] = ($event: any) => {$event.stopPropagation(); _ctx.openBericht($event, _ctx.recommendedBericht(_ctx.ba))})
                        }, [
                          _createVNode(_component_ion_icon, {
                            size: "large",
                            icon: _ctx.documentTextOutline
                          }, null, 8, ["icon"])
                        ]))
                      : (_ctx.ba?.hasPdfSupport())
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: "cursor-pointer",
                            type: "button",
                            fill: "solid",
                            shape: "round",
                            "data-cy": "btn-pdf-preview",
                            "btn-primary": true,
                            onClick: _cache[5] || (_cache[5] = ($event: any) => {$event.stopPropagation(); _ctx.$router.push(`/ba/${_ctx.ba?.id}/pdf-preview`)})
                          }, [
                            _createVNode(_component_ion_icon, {
                              size: "large",
                              icon: _ctx.clipboardOutline
                            }, null, 8, ["icon"])
                          ]))
                        : _createCommentVNode("", true),
                    (_ctx.featureFlags?.survey?.changeStatus)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 2,
                          class: "flex cursor-pointer",
                          title: _ctx.$t('immobilie.updateStatus'),
                          onClick: _cache[6] || (_cache[6] = ($event: any) => {$event.stopPropagation(); _ctx.handleStatusChange($event)})
                        }, [
                          _createVNode(_component_ion_icon, {
                            size: "large",
                            icon: _ctx.statusIconState
                          }, null, 8, ["icon"])
                        ], 8, _hoisted_10))
                      : _createCommentVNode("", true),
                    (_ctx.featureFlags?.survey?.changePerson)
                      ? (_openBlock(), _createBlock(_component_ProfileIcon, {
                          key: 3,
                          user: _ctx.gutachter,
                          onChange: _ctx.handlePersonChange
                        }, null, 8, ["user", "onChange"]))
                      : _createCommentVNode("", true),
                    (_ctx.alreadyInIndexDB)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 4,
                          class: "flex cursor-pointer",
                          onMouseover: _cache[7] || (_cache[7] = ($event: any) => (_ctx.openToast($event))),
                          onMouseout: _cache[8] || (_cache[8] = ($event: any) => (_ctx.closeToast())),
                          onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => {_ctx.closeToast(); _ctx.removeFromIndexDB();}, ["stop"]))
                        }, [
                          _createVNode(_component_ion_icon, {
                            size: "large",
                            icon: _ctx.arrowDownCircle
                          }, null, 8, ["icon"])
                        ], 32))
                      : _createCommentVNode("", true),
                    (_ctx.featureFlags.downloadSurvey && !_ctx.alreadyInIndexDB)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 5,
                          fill: "clear",
                          class: "flex cursor-pointer",
                          onClick: _cache[10] || (_cache[10] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.downloadBestandsaufnahme && _ctx.downloadBestandsaufnahme(...args)), ["stop"]))
                        }, [
                          _createVNode(_component_ion_icon, {
                            size: "large",
                            icon: _ctx.arrowDownCircleOutline
                          }, null, 8, ["icon"])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      class: "flex cursor-pointer",
                      onClick: _cache[11] || (_cache[11] = ($event: any) => ($event.stopPropagation(), _ctx.handleBaShare()))
                    }, [
                      _createVNode(_component_ion_icon, {
                        size: "large",
                        icon: _ctx.shareSocialOutline
                      }, null, 8, ["icon"])
                    ])
                  ], 2)
                ]))
              : _createCommentVNode("", true),
            (_ctx.uiMode === 'normal')
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    (_ctx.ba.malus)
                      ? (_openBlock(), _createBlock(_component_PdfChip, {
                          key: 0,
                          class: "mr-2",
                          value: _ctx.ba.malus,
                          color: _ctx.malusColors[_ctx.ba.malusColor],
                          big: true
                        }, null, 8, ["value", "color"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_HzbaButton, {
                      onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.handleStatusChange($event)))
                    }, {
                      default: _withCtx(() => [
                        (!_ctx.HzbaStatus[_ctx.ba.status])
                          ? (_openBlock(), _createElementBlock("span", _hoisted_13, " Status unbekannt "))
                          : (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.translatedHzbaStatus(_ctx.ba.status, _ctx.t)), 1)),
                        _createVNode(_component_ion_icon, {
                          size: "small",
                          icon: _ctx.statusIconState
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }),
                    (_ctx.alreadyInIndexDB)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: "alreadyInIndexDB",
                          onMouseover: _cache[13] || (_cache[13] = ($event: any) => (_ctx.openToast($event))),
                          onMouseout: _cache[14] || (_cache[14] = ($event: any) => (_ctx.closeToast())),
                          onClick: _cache[15] || (_cache[15] = ($event: any) => {$event.stopPropagation(); _ctx.closeToast(); _ctx.removeFromIndexDB()})
                        }, [
                          _createVNode(_component_ion_icon, { icon: _ctx.arrowDownCircle }, null, 8, ["icon"])
                        ], 32))
                      : _createCommentVNode("", true),
                    (_ctx.featureFlags?.survey?.changePerson)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          (_ctx.isMobile)
                            ? (_openBlock(), _createBlock(_component_ProfileIcon, {
                                key: 0,
                                user: _ctx.gutachter,
                                onChange: _ctx.handlePersonChange
                              }, null, 8, ["user", "onChange"]))
                            : (_openBlock(), _createBlock(_component_HzbaButton, {
                                key: 1,
                                onClick: _ctx.handlePersonChange
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.gutachter ? _ctx.gutachter.username : _ctx.$t('hzba.notAssigned')), 1)
                                ]),
                                _: 1
                              }, 8, ["onClick"]))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    (_ctx.featureFlags.downloadSurvey && !_ctx.alreadyInIndexDB)
                      ? (_openBlock(), _createBlock(_component_DownloadSurveyButton, {
                          key: 0,
                          "is-downloading": _ctx.isDownloading,
                          onOnClick: _ctx.downloadBestandsaufnahme
                        }, null, 8, ["is-downloading", "onOnClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    (_ctx.recommendedBericht(_ctx.ba))
                      ? (_openBlock(), _createBlock(_component_a_button, {
                          key: 0,
                          "btn-secondary": true,
                          class: "w-full p-2 h-10 text-base",
                          shape: "round",
                          "data-cy": "button-berichtAnzeigen",
                          onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.openBericht($event, _ctx.recommendedBericht(_ctx.ba))))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("hzba.buttons.berichtAnzeigen")), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.relevantMaengellist && _ctx.relevantMaengellist.length > 0)
                      ? (_openBlock(), _createBlock(_component_a_button, {
                          key: 1,
                          "btn-secondary": true,
                          class: "w-full mb-1 text-base",
                          shape: "round",
                          "data-cy": "button-mangellisteAnzeigen",
                          onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.openMaengelInImmobilie($event)))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("immobilie.buttons.mangellisteAnzeigen")), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.uiMode === 'normal')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: "flex cursor-pointer p-4 justify-end",
                  onClick: _cache[18] || (_cache[18] = ($event: any) => ($event.stopPropagation(), _ctx.handleBaShare()))
                }, [
                  _createVNode(_component_IonIcon, {
                    size: "small",
                    icon: _ctx.shareSocialOutline
                  }, null, 8, ["icon"])
                ]))
              : _createCommentVNode("", true)
          ], 2)
        ]),
        _: 1
      }, 8, ["class"]))
    : _createCommentVNode("", true)
}