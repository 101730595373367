import useBestandsaufnahmeSockets from "@/composables/Bestandsaufnahme/useBestandsaufnahmeSockets";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { BestandsaufnahmeJson } from "@/models/ba/interfaces/IBestandsaufnahme";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import Immobilie from "@/models/immobilie.model";
import { getCache, removeCache } from "@/store/storage/ionicStorage";
import { Monitoring } from "@/utilities/monitoring";
import { Network } from "@capacitor/network";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useBestandsaufnahmens } from "./useBestandsaufnahmens";

interface HzbaSubpage {
    onIonViewWillEnter: Function;
    onIonViewWillLeave?: Function;
    // onIonViewDidLeave: Function;
}

export default function useOnceBestandsaufnahmeSubpage(params: HzbaSubpage) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute()
    const user = computed(() => useUser().user.value)
    const currentProject = computed(() => store.state.user.currentUserProject)
    const { openChangeStatusAlert } = useBestandsaufnahmens()
    const socketBa = useBestandsaufnahmeSockets();

    let alert: any;

    const { t } = useI18n({ useScope: 'global' })

    const localSubpage = ref();

    const baId = computed<string>(() => { return router.currentRoute.value.params.baId && router.currentRoute.value.params.baId.toString(); })
    const baSubpageName = computed(() => { return localSubpage.value === undefined ? router.currentRoute.value.params.subpage : localSubpage.value; })

    const ba = computed<Bestandsaufnahme | undefined>(() => {
        return store.state.currentHzba.currentBa;
    })

    const immobilie = computed(() => { return ba.value && Immobilie.find(ba.value.immobilie); });
    const loaded = computed(() => BestandsaufnahmeModel.getters("loaded"));

    const createSurveyFromTemplate = async ( baId: number) => {
        const localBas = await BestandsaufnahmeModel.dispatch("loadPersistedBestandsaufnahmes")
        const localBa = localBas.find((el: any) => {
            return el.id === baId
        });
        if (!localBa ) {
            const cachedSurveyTemplate = await getCache('selectedSurveyTemplate');
            const parsedSurveyTemplate = JSON.parse(cachedSurveyTemplate)
            const cachedProperty = await getCache('selectedSurveyProperty')
            const selectedTemplate = parsedSurveyTemplate.attributes.vorlage
            const selectedPropertyId = (JSON.parse(cachedProperty)).id
            const storeBegehungsdatum = store.state.app.createSurvey.date

            selectedTemplate.id = baId
            selectedTemplate.status = 'IN_DURCHFUEHRUNG'
            selectedTemplate.immobilie = selectedPropertyId
            selectedTemplate.gutachter = user.value!.id
            selectedTemplate.isDownloaded = true
            selectedTemplate.surveyType = selectedTemplate.uid
            selectedTemplate.hasUnsavedChanges = true
            selectedTemplate.begehungsdatum = (new Date(storeBegehungsdatum)).toISOString()
            await BestandsaufnahmeModel.insertOrUpdate({ data: selectedTemplate });
            await removeCache('selectedSurveyProperty');
            store.commit('app/setCreateSurveyData', null);
        }
    }

    const setCurrentHzba = async () => {

        if (!baId.value) {
            Monitoring.error('No id given while setting current survey.');
            return
        }
        const id: number = parseFloat(baId.value);

        if (store.state.currentHzba.currentBa && id === store.state.currentHzba.currentBa.id) {
            console.log("Hzba already setup. Return here.")
            return;
        }

        const setBa = async () => {
            const networkStatus = await Network.getStatus();
            try {
                if (networkStatus.connected) {
                    const res = await BestandsaufnahmeModel.dispatch("fetchMoverSurveyById", { surveyId: id, saveToStore: false });
                    await BestandsaufnahmeModel.dispatch( "storeMoverSurvey",  res );
                } else {
                    await createSurveyFromTemplate(id);
                }
            } catch (err: any) {
                Monitoring.chainError("HZBA_CRITICAL_ERROR HZBA_LOGGING (FRONTEND) - Error fetching BA in setBa", err);
                await router.push('/bas');
                return;
            }
            // console.log("id.toString() !== baId.value.toString()", id.toString() !== baId.value.toString(),  id.toString(), baId.value.toString() )
            if ( !baId.value || id.toString() !== baId.value?.toString() ) {
                return;
            }
            const nextCurrentBa = BestandsaufnahmeModel.find(id);

            // IMPORTANT! runs always
            await store.dispatch("currentHzba/setCurrentHzba", nextCurrentBa);

            if ( !baId.value || id.toString() !== baId.value.toString() ) {
                return;
            }
            if (!nextCurrentBa?.areMinimumFragenblocksSet ) {
                if (  networkStatus.connected ) {
                    const copiedBa = await ba.value?.copyLeanJson({prepareForSync: true}); // TODO: use nextCurrentBa
                    // console.log('Copied BA JSON before uploading:', copiedBa);
                    // @ts-ignore
                    copiedBa["areMinimumFragenblocksSet"] = true;

                    const baUploadRes = await BestandsaufnahmeModel.api().put(`/bestandsaufnahmes/${copiedBa?.id}?projectId=${currentProject.value.id}`,
                        {data: copiedBa},
                        {save: false},
                    );

                    if ( !baId.value || id.toString() !== baId.value.toString() ) {
                        return;
                    }

                    const updatedBaJsonRes = (baUploadRes.getDataFromResponse() as any).data as BestandsaufnahmeJson;
                    const updatedBa = new Bestandsaufnahme(updatedBaJsonRes, undefined);

                    updatedBa.isLocal = false;
                    await store.commit('currentHzba/setCurrentBa', updatedBa);
                }
            }
            if ( !baId.value || id.toString() !== baId.value.toString() ) {
                return;
            }

            await store.dispatch("currentHzba/saveHzbaLocally");

            if ( !baId.value || id.toString() !== baId.value.toString() ) {
                return;
            }
            socketBa.subscribeToBestandsaufnahme();
        }

        if (loaded.value) {
            setBa();
        } else {
            watch(() => BestandsaufnahmeModel.getters("loaded"), async (value) => {
                setBa();
            });
        }
    }

    params.onIonViewWillEnter(async () => {
        store.commit("app/hideBottomNavigation");

        if (baId.value.toString() !== store.state.currentHzba.currentBa?.id.toString()) {
            await store.dispatch("currentHzba/setCurrentHzba", null);
            setCurrentHzba();
        }
    });

    params.onIonViewWillLeave && params.onIonViewWillLeave(async () => {
        alert && alert.value && alert.value.dismiss && alert.value.dismiss();
        socketBa.unsubscribeFromBestandsaufnahme()
        if (!route.path.startsWith("/ba/")) {
            await store.dispatch("currentHzba/setCurrentHzba", null);
        }
        return true;
    });

    return {
        baId,
        hzba: store.state.currentHzba.currentHzba,
        ba,
        baSubpageName,
        immobilie,
        localSubpage,
        openChangeStatusAlert
    }
}