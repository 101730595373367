<template>
  <div 
    v-if="isVisible"
    class="floating-button"
    ref="floatingButton"
    :style="{ top: `${position.top}px`, left: `${position.left}px` }"
    @mousedown="startDrag"
    @touchstart="startDrag"
  >
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';

  const props = defineProps({
    isVisible: {
      type: Boolean,
      required: true
    }
  })

  const emit = defineEmits([
    'onClick',
    'update:isVisible'
  ])

  const iconHeight = 40;
  const startingPos = {
    top: window.innerHeight - iconHeight - 20, // 20 is the margin
    left: window.innerWidth - iconHeight - 20
  };

  //Variables
  const floatingButton = ref();
  const isDragging = ref(false)
  const dragStarted = ref(false)
  const dragThreshold = 10
  const position = ref(startingPos);
  const startOffset = ref({
    x: 0,
    y: 0
  })

  //Watchers
  watch(() => props.isVisible, (newValue: boolean) => {
      if(!newValue) {
        removeEventListeners();
      }
    },
    {
      immediate: true
    }
  )

  //Methods
  function startDrag(event: any) {
    isDragging.value = false;
    dragStarted.value = false;
    startOffset.value = {
      x: (event.clientX || event.touches[0].clientX) - position.value.left,
      y: (event.clientY || event.touches[0].clientY) - position.value.top
    };
    floatingButton.value.addEventListener('mousemove', onDrag);
    floatingButton.value.addEventListener('mouseup', stopDrag);
    floatingButton.value.addEventListener('touchmove', onDrag);
    floatingButton.value.addEventListener('touchend', stopDrag);
  }

  function onDrag(event: any) {
    const clientX = event.clientX || event.touches[0].clientX;
    const clientY = event.clientY || event.touches[0].clientY;
    const dx = clientX - startOffset.value.x - position.value?.left ?? 0;
    const dy = clientY - startOffset.value.y - position.value?.top ?? 0;

    if (Math.abs(dx) > dragThreshold || Math.abs(dy) > dragThreshold) {
      isDragging.value = true;
      dragStarted.value = true;
    }

    if (isDragging.value) {
      position.value = {
        top: clientY - startOffset.value.y,
        left: clientX - startOffset.value.x
      };
    }
    event.preventDefault();
    event.stopPropagation();
  }
  const offset = iconHeight / 2;
  function isElementVisible(element: HTMLElement) {
      const rect = element.getBoundingClientRect();

      // Check if the element is within the viewport
      if (
          rect.top + offset >= 0 &&
          rect.left + offset >= 0 &&
          rect.bottom - offset <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right - offset <= (window.innerWidth || document.documentElement.clientWidth)
      ) {
          // Check if the element is visible and not covered by another element
          const centerX = (rect.left + rect.right) / 2;
          const centerY = (rect.top + rect.bottom) / 2;

          const topmostElement = document.elementFromPoint(centerX, centerY);

          // Ensure the element is the topmost at its center point
          if (topmostElement === element || element.contains(topmostElement)) {
              return true;
          }
      }
      return false;
  }

  function stopDrag(event: any) {
    floatingButton.value.removeEventListener('mousemove', onDrag);
    floatingButton.value.removeEventListener('mouseup', stopDrag);
    floatingButton.value.removeEventListener('touchmove', onDrag);
    floatingButton.value.removeEventListener('touchend', stopDrag);

    if (!dragStarted.value) {
      emit('onClick')
      emit('update:isVisible', false);
    }

    isDragging.value = false;
    dragStarted.value = false;

    if(!isElementVisible(floatingButton.value as HTMLElement)) {
      position.value = startingPos;
    }
  }

  function removeEventListeners() {
    document.removeEventListener('mousemove', onDrag);
    document.removeEventListener('mouseup', stopDrag);
    document.removeEventListener('touchmove', onDrag);
    document.removeEventListener('touchend', stopDrag);
  }
</script>

<style scoped>
.floating-button {
  position: fixed;
  width: 40px;
  height: 40px;
  background-color: var(--white100);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.15s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.floating-button:active {
  transform: scale(0.75);
}
</style>
