<template>
  <ion-page v-if="immobilie">
    <Toolbar
      :title="t('hzba.pdf.vorschauTitel')"
      show-back-button
      @onBackButtonClicked="() => {}"
    >
      <template #leading>
        <ion-buttons slot="start">
          <ion-button @click="$router.replace(goBackUrl)">
            <ion-icon
              slot="icon-only"
              :icon="arrowBack"
            />
          </ion-button>
        </ion-buttons>
      </template>

      <template #trailing>
        <ion-buttons
          slot="primary"
        >
          <ion-button
            slot="end"
            color="light"
            @click="downloadPdf"
          >
            {{ t("hzba.buttons.download") }}
          </ion-button>
        </ion-buttons>
      </template>
    </Toolbar>
    <ion-content>
      <PdfViewer
        ref="pdfViewer"
        download-name="anlagenschema.pdf"
        :go-back-url="goBackUrl"
      >
        <pdf-anlagenschema-template
          :hzba="hzba"
          :immobilie="immobilie"
        />
        <section class="pdf-content">
          <h1>Anlagenschema goes here</h1>
        </section>
      </PdfViewer>
    </ion-content>
  </ion-page>
</template>

<script>
import Toolbar from "@/components/Navigation/Toolbar";
import PdfAnlagenschemaTemplate from '@/components/Pdf/Bericht_Anlagenschema/PdfAnlagenschemaTemplate.vue';
import { default as PdfHzbaPreview, default as PdfHzbaTemplate } from "@/components/Pdf/Bericht_Heizung/PdfHzbaTemplate";
import PdfViewer from "@/components/Pdf/PdfPreviewerAndCreator";
import PdfPageSkeleton from "@/components/PdfPageSkeleton";
import { useStore } from "@/composables/useTypedStore";
import Immobilie from "@/models/immobilie.model";
import { getPhotoUrl } from "@/utilities/get-media-url";
import { IonButton, IonButtons, IonContent, IonIcon, IonPage } from "@ionic/vue";
import { computed, ref } from "vue";
import { useI18n } from 'vue-i18n';



export default {
  name: "PdfPreviewAnlagenschema",
  components: {
    PdfPageSkeleton,
    Toolbar,
    IonIcon,
    IonButtons,
    IonButton,
    PdfHzbaTemplate,
    PdfHzbaPreview,
    IonContent,
    PdfViewer,
    IonPage,
    PdfAnlagenschemaTemplate,
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' })
    const pdfViewer = ref();
    const goBackUrl='/properties/'

    // TODO!
    const propertyIdFromUrl = 1
    const immobilie = computed(() => {
      if (propertyIdFromUrl){
        return Immobilie.find(propertyIdFromUrl);
      }
      return undefined;
    });

    const downloadPdf = () => {
      pdfViewer.value.downloadPdf();
    }

    return {
      immobilie,
      downloadPdf,
      pdfViewer,
      goBackUrl,
      getPhotoUrl,
      t
    }
  },
};
</script>

<style scoped>

</style>