<template>
  <div class="documentItem flex flex-col">
    <div>
      <AImage
        v-if="getType(document.dokument) === 'photo'"
        :src="getPhotoUrl(document.dokument, { thumbnail: true })"
        :image-class="'photo object-contain rounded-lg w-full h-200'"
        @click="openGallery"
      />
      <APdfThumbnail
        v-if="getType(document.dokument) === 'pdf'"
        :id="`${document.name}_${document.id}`"
        :url="getPdfUrl(document.dokument)"
        :file="document"
        :image-class="'photo object-contain rounded-lg w-full h-200'"
        @click="openPdfViewerModal(document.dokument)"
      />
    </div>
    <div class="text-center pb-4">
      {{ getLocalization(document.name) }}
    </div>
    <div class="text-center pb-4">
      {{ getLocalization(document.beschreibung) }}
    </div>
  </div>
</template>

<script
  lang="ts"
  setup
>
import AImage from "@/components/Base/AImage";
import APdfThumbnail from "@/components/Base/APdfThumbnail";
import useAlert from '@/composables/useAlert';
import getLocalization from "@/utilities/get-localization";
import { getPdfUrl, getPhotoUrl } from '@/utilities/get-media-url';
import { openBaseModal } from "@/utilities/modal-helper";
import { openPdfViewerModal } from '@/utilities/pdf-viewer';
import { computed } from "vue";
import { useI18n } from 'vue-i18n';

const props = defineProps({
  document: {
    type: Object,
    required: true
  }
});
const emit = defineEmits(["delete"]);

const alert = useAlert();
const { t } = useI18n();

const images = computed(() => {
  const doc = props.document;
  return [doc.dokument];
});

const getType = (d: any) => {
  if (!d) {
    return undefined;
  }

  if (d.ext === '.pdf' || d.ext === '.PDF') {
    return 'pdf';
  }

  if (d.ext === 'iframe') {
    return 'iframe-url';
  }

  return 'photo';
};

const deleteDocumentAlert = (modal: any) => {
  alert.showAdv(
    t("alerts.deleteTitle"),
    t("alerts.deleteDescription"),
    [
      { text: t("alerts.deleteCancel"), },
      {
        text: t("alerts.deleteConfirm"),
        handler: () => {
          emit("delete");
          modal.dismiss();
        }
      },
    ]
  );
};

const openGallery = async () => {
  const modal = await openBaseModal('gallery', {
    images: images.value,
    title: getLocalization(props.document.name),
    downloadEnabled: true,
    onDelete: () => {
      if (modal) { 
        deleteDocumentAlert(modal)
      }
    }
  });
};

</script>

<style lang="scss">
.h-200 {
  height: 200px;
}
</style>
