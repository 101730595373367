<template>
  <ion-content
    v-if="user"
    class="ion-padding"
    style="background: white"
  >
    <div class="mb-2">
      {{ t('userProject.organization') }}: {{ user.organisation.name }}
    </div>
    <div>
      <strong>{{ t(`${organisationPrefix}userProject.projectName`) }}: {{ currentProject?.name }}</strong>
    </div>
    <div
      v-if="user.organisation.projects.length > 1"
      class="flex flex-col mt-4"
    >
      <AButton
        data-cy="button-project"
        :btn-primary="true"
        class="mb-2"
        :expand="true"
        @click="goToChangeProject"
      >
        {{ t('userProject.changeProject') }}
      </AButton>
    </div>
  </ion-content>
</template>

<script setup lang="ts">
import { IonContent, popoverController, useIonRouter } from '@ionic/vue';
import { ComputedRef, computed } from 'vue';
import { useI18n } from 'vue-i18n';
//Composables
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
//Components
import AButton from "@/components/Base/AButton";
import User from '@/models/user';

//Variables
const store = useStore()
const { user } = useUser();
const router = useIonRouter();
const { t } = useI18n({ useScope: 'global' })

//Computed Variables
const currentProject = computed(() => store.state.user.currentUserProject);
const organisationPrefix: ComputedRef<string> = computed(() => {
  const currentUser: User | null = User.query().first();

  return currentUser ? currentUser.organisationPrefix : "";
});
//Methods
function dismiss() {
  popoverController.dismiss();
}

function goToChangeProject() {
  router.push('/project-selection')
  dismiss();
}
</script>

<style scoped>

ion-content {
  --background: white;
}
</style>