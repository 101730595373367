import { exponentialBackoff, ExponentialBackoffOptions } from "./api-helpers";
import { chainError } from "./custom-error";

type AsyncFunc = () => Promise<any>;

export const createAsyncQueue = (retryOptions?: ExponentialBackoffOptions) => {

    const queue: AsyncFunc[] = [];
    let isProcessing = false;

    const processQueue = async () => {
        isProcessing = true;

        while (queue.length > 0 && isProcessing) {
            const task = queue.shift(); // Get the next task in the queue
            if (task) {
                try {
                    await exponentialBackoff(task, retryOptions ?? { retries: 3, initialDelay: 300 });
                } catch (err: any) {
                    isProcessing = false;
                    throw chainError(`Error while executing async task ${task.name} in queue`, err);
                }
            }
        }

        isProcessing = false;
    };
    const enqueue = async (task: AsyncFunc) => {
        queue.push(task);
        if (!isProcessing) {
            return processQueue();
        }
    };

    return { enqueue };
};
