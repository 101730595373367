import { deleteDocumentFromObject } from "@/api/ModelApi";
import { SortTermActive } from "@/composables/Sort/SortTerms";
import { BestandsaufnahmeJson } from "@/models/ba/interfaces/IBestandsaufnahme";
import { ImmobilieStatus } from "@/models/immobilie/interfaces/IImmobilie";
import User from "@/models/user";
import { Monitoring } from "@/utilities/monitoring";
import { getCurrentSortTermWithFunction, sortArrayByProperty } from "@/utilities/sortArrayByProperty";
import { Model } from '@vuex-orm/core';
import Dokument from './dokument.model';
import ErweitertesMedium from './erweitertes-medium.model';
import StrapiMedia from './photo/strapi-media.model';

export default class Immobilie extends Model {

    static entity = 'immobilie';

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(null),
            status: this.attr(null),
            eigentuemer: this.attr(null),
            strasse: this.attr(null),
            plz: this.attr(null),
            stadt: this.attr(null),
            stadtbezirk: this.attr(null),
            verwalters: this.attr(null),
            externeObjektNr: this.attr(null),
            technikzentraleBericht: this.attr(null),
            baujahr: this.attr(null),
            bilder: this.attr(null),
            vorschaubild: this.attr(null),
            videos: this.attr(null),
            drohnenbilder: this.attr(null),
            imageVideo: this.attr(null),
            imageVideoLink: this.attr(null),
            anlageschema: this.attr(null),
            geolocationLat: this.attr(null),
            geolocationLon: this.attr(null),
            grundrisss: this.attr(null),
            matterports: this.attr(null),
            dokuments: this.attr(null),
            updatedAt: this.attr(null),
            isDownloaded: this.attr(null),
            hasUnsavedChanges: this.attr(false),
            bestandsaufnahmes: this.attr(null),
            grundrissAnzeigen: this.attr(null),
            drohnenbilderAnzeigen: this.attr(null),
            matterportsAnzeigen: this.attr(null),
            managementSummaries: this.attr(null),
            otherDokuments: this.attr(null),
            notes: this.attr(null)
        };
    }


    id!: number;
    name!: string;
    status!: ImmobilieStatus;
    eigentuemer!: string;
    strasse!: string;
    plz!: string;
    stadt!: string;
    stadtbezirk!: string;
    verwalters!: User[];
    baujahr!: number;
    externeObjektNr!: string;
    technikzentraleBericht!: StrapiMedia;
    bilder!: StrapiMedia[];
    vorschaubild!: StrapiMedia;
    videos!: StrapiMedia[];
    drohnenbilder!: StrapiMedia[]
    imageVideo!: StrapiMedia;
    imageVideoLink!: string;
    anlageschema!: StrapiMedia;
    geolocationLat!: number;
    geolocationLon!: number;
    grundrisss!: ErweitertesMedium[];
    matterports!: ErweitertesMedium[];
    dokuments!: Dokument[];
    managementSummaries!: Dokument[];
    otherDokuments!: Dokument[];
    notes: any;
    bestandsaufnahmes!: BestandsaufnahmeJson[];
    updatedAt!: Date;
    isDownloaded!: boolean;
    hasUnsavedChanges!: boolean;
    grundrissAnzeigen!: boolean;
    drohnenbilderAnzeigen!: boolean;
    matterportsAnzeigen!: boolean;
}

export const immobilie = {
    state: {
        propertiesLoaded: false,
        page: 0,
        pageCount: undefined,
        searchResultsCount: null as number | null,
        totalSearchResultsCount: null as number | null,
        sortBy: { label: 'Leitungsanlage', fieldName: 'stadt', orderBy: 'asc' },
        searchTerm: "",
        statusFilter: [],
        personFilter: [],
        surveyDatumFilter: [],

        persistedPropertiesLoaded: false,
        persistedProperties: undefined,

        fallbackProperties: [],
    },
    actions: {
        setLoaded: ({ state }: { state: any }) => {
            state.propertiesLoaded = true;
        },
        setPage: ({ state }: { state: any }, page: number) => {
            state.page = page;
        },
        setPageCount: ({ state }: { state: any }, pageCount: number) => {
            state.pageCount = pageCount;
        },
        setSortBy: ({ state }: { state: any }, sortBy: SortTermActive) => {
            state.sortBy = sortBy;
        },
        setSearchTerm: ({ state }: { state: any }, searchTerm: string) => {
            state.searchTerm = searchTerm;
        },
        setStatusFilter: ({ state }: { state: any }, statusFilter: string) => {
            state.statusFilter = statusFilter;
        },
        setPersonFilter: ({ state }: { state: any }, personFilter: string) => {
            state.personFilter = personFilter;
        },
        setSurveyDatumFilter: ({ state }: { state: any }, surveyDatumFilter: string) => {
            state.surveyDatumFilter = surveyDatumFilter;
        },
        loadPersistedProperties: async ({ state }: { state: any }) => {
            const { immobilie } = await Immobilie.dispatch("$fetchFromLocal");
            return immobilie || [];
        },
        /**
         * When ba's gets loaded, we get information of properties within.
         * But we cannot store those properties to the store of the other properties as this would bring confusion to the pagination but also filter system.
         * That is why we add those properties to a separate storage.
         */
        addToFallbackProperties: async ({ state }: { state: any }, immobilie: any) => {
            if (!immobilie) { return; }

            if (!state.fallbackProperties.find((el: any) => el.id === immobilie.id)) {
                state.fallbackProperties.push(immobilie);
            }
        },
        updateDokumentsField: async ({ state }: { state: any }, { id, newDocuments }: { id: number, newDocuments: any[] }) => {
            const object = Immobilie.find(id);
            if (object) {
                const objectJson = object.$toJson();
                objectJson.dokuments = objectJson.dokuments.filter((dok: any) => newDocuments.some(d => d.id === dok.id));

                await Immobilie.dispatch('storeMoverObject', objectJson );

                return true;
            }
            return false;
        },
        deleteDocument: async ({ dispatch }: { dispatch: any }, { documentId, objectId }: { documentId: number, objectId: number }) => {
            try {
                const response = await deleteDocumentFromObject(objectId, documentId);
                return await dispatch('updateDokumentsField', { id: objectId, newDocuments: response.dokuments });
            } catch (error: any) {
                Monitoring.withScope((scope) => {
                    scope.setContext("Delete document from object", { documentId, objectId });
                    Monitoring.error(error);
                });
                return false;
            }
        },
        deleteImage: async ({ state }: { state: any }, { objectId, mediaIdentifier }: { objectId: number, mediaIdentifier: number | string }) => {
            try {
                const object = Immobilie.find(objectId);
                if (object) {
                    const objectJson = object.$toJson();
                    let changed = false;
                    if (objectJson.vorschaubild?.id === mediaIdentifier || objectJson.vorschaubild?.webPath === mediaIdentifier) {
                        objectJson.vorschaubild = null;
                        changed = true;
                    }
                    if (typeof mediaIdentifier === "number") {
                        if (objectJson.bilder?.some((bild: any) => bild.id === mediaIdentifier)) {
                            objectJson.bilder = objectJson.bilder.filter((bild: any) => bild.id !== mediaIdentifier);
                            changed = true;
                        }
                    } else {
                        if (objectJson.bilder?.some((bild: any) => bild.webPath === mediaIdentifier)) {
                            objectJson.bilder = objectJson.bilder.filter((bild: any) => bild.webPath !== mediaIdentifier);
                            changed = true;
                        }
                    }
                    if (changed) {
                        await Immobilie.dispatch('storeMoverObject', objectJson );
                    }
                    return true;
                }
                return false;
            } catch (error: any) {
                Monitoring.withScope((scope) => {
                    scope.setContext("Delete image from object", { objectId, mediaIdentifier });
                    Monitoring.chainError("Error while deleting image from object/property", error);
                });
                return false;
            }
        },
        storeMoverObject: async ({ state }: { state: any }, moverObjectJson: any) => {
            console.log("storeMoverObject", moverObjectJson);
            if ( moverObjectJson.isDownloaded ) {
                await Immobilie.dispatch('$updateLocally', { data: moverObjectJson });

            } else {
                await Immobilie.insertOrUpdate({ data: moverObjectJson });
            }
        }
    },
    getters: {
        propertiesLoaded: (state: any) => state.propertiesLoaded,
        page: (state: any) => state.page,
        pageCount: (state: any) => state.pageCount,
        sortBy: (state: any) => state.sortBy,
        searchTerm: (state: any) => state.searchTerm,
        statusFilter: (state: any) => state.statusFilter,
        personFilter: (state: any) => state.personFilter,
        surveyDatumFilter: (state: any) => state.surveyDatumFilter,
        persistedProperties: (state: any) => Immobilie.query().where('isDownloaded', true).get(),
        propMatchesFilter: (state: any, getters: any) => (property: Immobilie) => {
            if (!property) return false;
            if (!getters.searchTerm) return true;

            const lowerCaseSearchTerm = getters.searchTerm.toLowerCase();

            return (
                (property.externeObjektNr ? property.externeObjektNr.toLowerCase().includes(lowerCaseSearchTerm) : false) ||
                (property.strasse ? property.strasse.toLowerCase().includes(lowerCaseSearchTerm) : false) ||
                (property.stadt ? property.stadt.toLowerCase().includes(lowerCaseSearchTerm) : false) ||
                (property.plz ? property.plz.toLowerCase().includes(lowerCaseSearchTerm) : false) ||
                (property.name ? property.name.toLowerCase().includes(lowerCaseSearchTerm) : false) ||
                (property.eigentuemer ? property.eigentuemer.toLowerCase().includes(lowerCaseSearchTerm) : false) ||
                (property.baujahr !== null && property.baujahr !== undefined ? property.baujahr.toString().toLowerCase().includes(lowerCaseSearchTerm) : false)
            );
        },
        getFallbackProperties: (state: any) => {
            return state.fallbackProperties;
        },
        getPropOrFallbackProp: (state: any) => (id: number) => {
            const prop = Immobilie.find(id)
            if (prop) { return prop }

            return state.fallbackProperties.find((el: any) => el.id === id);
        },

        matchesFilters: (state: any, getters: any) => (property: Immobilie) => {
            const statusMatch = getters.statusFilter.length === 0 || getters.statusFilter.includes(property.status);
            const personMatch = getters.personFilter.length === 0 || property.verwalters.some((person) => getters.personFilter.includes(person.username));

            return statusMatch && personMatch;
        },

        // Main getter to retrieve mover objects
        moverObjects: (state: any, getters: any, rootState: any, rootGetters: any) => {
            const allProperties = rootGetters['app/isOffline'] ? getters.persistedProperties : Immobilie.query().with('bestandsaufnahmes').get();
            const filteredSortedProperties = allProperties
                .filter((property: any) => {
                    const matchesFilters = getters.matchesFilters(property);
                    const matchesSearch = getters.propMatchesFilter(property);

                    return matchesFilters && matchesSearch;
                })
                .sort((a: any, b: any) => sortArrayByProperty(a, b, getCurrentSortTermWithFunction(getters.sortBy)));

            return filteredSortedProperties;
        },
    }
}