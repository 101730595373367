<template>
  <footer>
    <h2>Copyright &#169; MovingLayers {{ new Date().getFullYear() }}</h2>
    <ion-button class="footerbutton" @click="goToImpressum">{{ $t('toolbar.impressum') }}</ion-button>
    <ion-button class="footerbutton" @click="goToPrivacy">{{ t('toolbar.privacy') }}</ion-button>
  </footer>
</template>

<script>
import { IonButton, useIonRouter } from "@ionic/vue";
import { defineComponent } from "vue";
import { useI18n } from 'vue-i18n';

export default defineComponent( {
  name: "FooterComp",
  components: {
    IonButton
  },
  setup() {
    const router = useIonRouter();
    const { t } = useI18n({ useScope: 'global' })

    function goToImpressum() {
      router.navigate('/impressum', 'forward', 'push');
    }
    function goToPrivacy() {
      router.navigate('/privacy', 'forward', 'push');
    }
    return {
      goToImpressum,
      goToPrivacy,
      t
    }
  }
});
</script>


<style scoped lang="scss">
footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
  background: var(--primary);
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;

  animation: animatedgradient 30s ease infinite;


  @media (max-height: 650px) {
    position: static;
  }

  h2 {
    font-family: var(--secondaryFont);
    color: var(--white100);
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
    margin: 10px 10px;
  }
  
  @-webkit-keyframes animatedgradient{
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }
  @-moz-keyframes animatedgradient {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }
  @keyframes animatedgradient {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }

}

.footerbutton {
    --background: transparent;
    --color:  var(--white100);
    --padding-top: 3px;
    --padding-bottom: 3px;
    --padding-end: 6px;
    --padding-start: 6px;

    color: var(--white100);
    font-feature-settings: "ss06" 1;
    font-family: ABCGravity-Normal,sans-serif;
    font-size: 14px;
    line-height: 21px;
  }


</style>