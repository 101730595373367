<template>
  <p
    class="mt-1 mb-0 flex items-center"
    :class="readonly ? '' : 'cursor-pointer'"
    @click="handlePropagation"
  >
    <span
      v-if="modelValue && format === 'text'"
      class="sm:mb-0 inline sm:block mr-2"
    >{{ title }}: </span>
    <span
      :id="triggerDate"
      :class="format === 'icon' ? 'icon-format-styles' : 'text-format-styles'"
    >
      <IonIcon
        v-if="format === 'icon'"
        :icon="dateIcon"
        class="mr-2"
        :title="title"
      />{{ displayDate }}</span>
    <span
      :id="triggerTime"
      :class="format === 'icon' ? 'icon-format-styles' : 'text-format-styles'"
    >
      <ion-icon
        v-if="modelValue && format === 'icon'"
        :icon="timeOutline"
        class="ml-3 mr-1"
      />{{ displayTime }}
    </span>
    <span
      :id="triggerDate2"
      :class="format === 'icon' ? 'icon-format-styles' : 'text-format-styles'"
    >
      <IonIcon
        v-if="modelValue && format === 'icon'"
        :icon="createOutline"
        class="ml-2"
      />
    </span>
  </p>
  <MultiplePopover
    :triggers="readonly ? [undefined] : [triggerDate, triggerDate2]"
  >
    <ion-datetime
      presentation="date"
      size="fixed"
      :done-text="$t('hzba.buttons.select')"
      :cancel-text="$t('hzba.buttons.abbrechen')"
      :value="modelValue"
      :show-default-buttons="true"
      :locale="locale"
      :disabled="loading"
      @ion-change="changeDate($event)"
      @ion-cancel="cancel"
    />
  </MultiplePopover>

  <!-- TODO: check if code duplication can be avoided by setting trigger id manually -->
  <ion-popover
    :keep-contents-mounted="true"
    :trigger="readonly ? undefined : triggerTime"
    trigger-action="click"
  >
    <ion-datetime
      presentation="time"
      size="fixed"
      :done-text="$t('hzba.buttons.select')"
      :cancel-text="$t('hzba.buttons.abbrechen')"
      :value="modelValue"
      :show-default-buttons="true"
      :locale="locale"
      :disabled="loading"
      @ion-change="changeDate($event)"
      @ion-cancel="cancel"
    />
  </ion-popover>
</template>

<script
  setup
  lang="ts"
>
import { generateUUID } from "@ionic/cli/lib/utils/uuid";
import {
    IonDatetime,
    IonIcon,
    IonPopover
} from "@ionic/vue";
import {
    calendarClearOutline,
    createOutline,
    timeOutline
} from "ionicons/icons";
import moment from "moment";
import { computed, PropType, ref } from "vue";
import { useI18n } from 'vue-i18n';
import MultiplePopover from "./MultiplePopover.vue";

// @ts-ignore - does not like [null, String]
const props = defineProps({
  format: {
    type: String as PropType<'icon' | 'text'>,
    required: true
  },
  modelValue: {
    type: [null, String],
    nullable: true,
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    required: true,
  },
  readonly: {
    type: Boolean,
    default: false
  },
  dateIcon: {
    type: String,
    default: calendarClearOutline
  },
});

const emit = defineEmits(["cancel", "changed", "update:modelValue"]);

const { locale, t } = useI18n();

const triggerDate = generateUUID();
const triggerDate2 = generateUUID();
const triggerTime = generateUUID();

const lastEmitValue = ref<string | null>(null);

const displayDate = computed(() =>
  props.modelValue ?
    props.format === "icon"
      ? moment(props.modelValue).format("DD.MM.YYYY")
      : `${new Date(props.modelValue).toLocaleDateString(locale.value)}, `
    : `${props.title}: ${t("hzba.datum.noDateSet")}`
);

const displayTime = computed(() =>
  props.modelValue ?
    props.format === "icon"
      ? moment(props.modelValue).format("HH:mm")
      : new Date(props.modelValue).toLocaleTimeString(locale.value)
    : ""
);

const changeDate = (newDate: any) => {
  if (newDate.detail.value === lastEmitValue.value) {
    // for some reason different ion-datetime components react to the same confirmation button, filter out same values for this reason
    return;
  }
  // TODO: not optimal to call emit twice, would need to refactor API call in useDateChangeUtils
  emit("update:modelValue", newDate.detail.value);
  emit("changed");
  lastEmitValue.value = newDate.detail.value;
};

const cancel = () => {
  emit("cancel");
};

const handlePropagation = (event: MouseEvent) => {
  if (!props.readonly) {
    event.stopPropagation();
  }
};

</script>

<style
  lang="scss"
  scoped
>
// is is tricky to style the ion-datetime component, since the ::part selector did not work
// setting the colors manually, since --ion-color-base derives from --primary
ion-datetime,
ion-datetime-button {
  --ion-color-base: #511A3F !important;
  --primary: #852565 !important;
}

.text-format-styles {
  all: inherit;
  margin-top: 0;
  white-space: pre;
}

.icon-format-styles {
  all: inherit;
}
</style>