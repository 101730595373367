import { useProperties } from "@/composables/Property/useProperties";
import { UploadBaPhotos } from "@/composables/Upload/UploadBaPhotos";
import { useCreateSurvey } from "@/composables/useCreateSurvey";
import { useStore } from "@/composables/useTypedStore";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import Immobilie from "@/models/immobilie.model";
import { getCache, removeCache } from "@/store/storage/ionicStorage";
import { Network } from "@capacitor/network";
import { computed } from "vue";
import { handleOfflineSurveyData } from "./helper";

export async function handleOfflineSurveys() {
  const store = useStore();
  const currentProject = computed(() => store.state.user.currentUserProject)
  const offlineSurveys = computed(() => BestandsaufnahmeModel.getters("persistedBestandsaufnahmes"))
  const networkState = await Network.getStatus()
    if(networkState.connected && offlineSurveys.value && offlineSurveys.value.length ) {
      let redirect = null;
      
      for( let i=0; i<offlineSurveys.value.length; i++ ) {
        if ( offlineSurveys.value[i].hasUnsavedChanges ) {
          let surveyResp;
          const hasEntryInDatabase = offlineSurveys.value[i].createdAt;
        

          store.commit('currentHzba/setPhotoUploadTasks', new UploadBaPhotos());
          const photoUploadTasks = new UploadBaPhotos();
          const baAsClass = new Bestandsaufnahme( offlineSurveys.value[i].copyJson(), undefined );
          const immobilie = Immobilie.find( baAsClass.immobilie ) as Immobilie;
          photoUploadTasks.getPhotoUploadTasks( baAsClass, immobilie );
          const copiedBa = await baAsClass.copyLeanJson({prepareForSync: true});
          if (hasEntryInDatabase) {
            surveyResp = await BestandsaufnahmeModel.api().put(`/bestandsaufnahmes/${offlineSurveys.value[i].id}?projectId=${currentProject.value.id}`,
              { data: copiedBa },
              { save: false },
            );
          } else {
            const body = {
              date: new Date(),
              userId: offlineSurveys.value[i].gutachter,
              property: offlineSurveys.value[i].immobilie,
              type: offlineSurveys.value[i].surveyType,
            }
            const offlineId = offlineSurveys.value[i].id;
            const response = await useCreateSurvey().sendDataToBackend(body)
            const createdBa = response?.data.data
            const survey = await handleOfflineSurveyData(offlineSurveys.value[i], createdBa)
            surveyResp = await BestandsaufnahmeModel.api().put(`/bestandsaufnahmes/${survey.id}?projectId=${currentProject.value.id}`,
              { data: survey },
              { save: false },
            );
            if (store.state.app.currentRoute.name === "hzbaView" && offlineId === store.state.currentHzba.currentBa.id) {
              redirect = `/ba/${survey.id}`;
            }
          }
          const updatedBaJsonRes = (surveyResp.getDataFromResponse() as any).data;
          updatedBaJsonRes.hasUnsavedChanges = false;
          const updatedBa = new Bestandsaufnahme(updatedBaJsonRes, undefined);

          photoUploadTasks.reassignPhotosToBa(updatedBa);
          updatedBa.isLocal = false;
          updatedBa.hasLocalImages = true;

          const imageUploadRes = await photoUploadTasks.uploadPhotos(updatedBa, store);
          const imageUploadSuccess = imageUploadRes.success;

          updatedBa.hasLocalImages = !imageUploadSuccess;
          const updatedBaJson = await updatedBa.toClassJson()
          updatedBaJson.isDownloaded = true;
          await BestandsaufnahmeModel.dispatch('storeMoverSurvey', updatedBaJson );
          if(!hasEntryInDatabase) {
            await BestandsaufnahmeModel.dispatch('$deleteFromLocal', offlineSurveys.value[i].id);

            const { considerRemoveImmobilieFromDownloaded } = useProperties();
            await considerRemoveImmobilieFromDownloaded(offlineSurveys.value[i]?.immobilie ?? -1)
          }
        }
      }
      if(redirect) {
        store.state.app.router?.push(redirect);
      }
    }
}

async function handleOfflineCreatedSurveys(userProject: any) {
  const data = await getCache('offlineSurveys')
  const offlineSurveys = JSON.parse(data)
  if(offlineSurveys && offlineSurveys.length) {
    for(let i=0;i<offlineSurveys.length;i++) {
      const body = {
        date: new Date(),
        userId: offlineSurveys[i].gutachter,
        property: offlineSurveys[i].immobilie,
        type: offlineSurveys[i].surveyType,
      }
      const response = await useCreateSurvey().sendDataToBackend(body)
      const createdBa = response?.data.data
      const survey = await handleOfflineSurveyData(offlineSurveys[i], createdBa)
      await BestandsaufnahmeModel.api().put(`/bestandsaufnahmes/${survey.id}?projectId=${userProject.value.id}`,
        { data: survey },
        { save: false },
    );
      await BestandsaufnahmeModel.dispatch('$deleteFromLocal', offlineSurveys[i].id);
      // await BestandsaufnahmeModel.dispatch('removeFromPersistedBestandsaufnahmes', offlineSurveys[i]);
    }
    await removeCache('offlineSurveys')
  } else {
    return
  }
}

async function handleOnlineCreatedSurveys(userProject: any) {
  const data = await getCache('onlineSurveys')
  const onlineSurveys = JSON.parse(data)
  if(onlineSurveys && onlineSurveys.length) {
    for(let i=0;i<onlineSurveys.length;i++) {
      await BestandsaufnahmeModel.api().put(`/bestandsaufnahmes/${onlineSurveys[i].id}?projectId=${userProject.value.id}`,
        { data: onlineSurveys[i] },
        { save: false },
      );
    }
    await removeCache('onlineSurveys')
  } else {
    return
  }
}