import { useStore } from "@/composables/useTypedStore";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import Immobilie from "@/models/immobilie.model";
import { computed } from "vue";

export default function useCurrentBaState() {
    const store = useStore();

    const ba = computed<Bestandsaufnahme | undefined>(() => { return store.state.currentHzba.currentBa;});
    const immobilie = computed(() => { return ba.value && Immobilie.find(ba.value.immobilie);});

    const isNotSubmitted = computed(() => { return (ba.value?.status !== "ABGESCHLOSSEN") && (ba.value && ba.value.status !== "FREIGEGEBEN") });

    const totalProgress = computed(() => {
        const prog = ba.value?.getTotalProgress();
        return prog && Math.round(prog)
    })


    /**
     * Returns if hzba is submittable.
     * This becomes false directly after pressing the "abschließen" button
     */
    const isSubmittable = computed(() => {
        return (isNotSubmitted.value
            && (ba.value?.unterschrifts?.length ?? 0) > 0
            && (ba.value?.fazit?.length ?? 0) > 0
            && totalProgress.value === 100)
    });

    const progressByTabs = computed(() => { return store.getters["currentHzbaProgressIndicator/tabs"]; });

    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );

    return {
        ba,
        immobilie,
        isNotSubmitted,
        totalProgress,
        isSubmittable,
        progressByTabs,
        isReadonly,
    }
}