import { chainError } from "./custom-error";
import { environment } from '../../environments/environment';

export type RetryOptions =
    { retries?: number; initialDelay?: number; infinite?: boolean; };

export type ExponentialBackoffOptions = RetryOptions & {
    maxDelay?: number;
    factor?: number;
    onRetry?: (attempt: number, error: any) => void;
};

// for circular dependencies with asynchronous wait times
// e.g. remote connect => fail => reconnect => fail => reconnect => etc.
// uses exponential backoff to retry an API call
export const retryHelper = ({ retries = 5, initialDelay = 1000, infinite = false }: RetryOptions
    = { retries: 5, initialDelay: 1000, infinite: false }) => {

    let attempt = 0;

    const retry = async (apiCall: (...args: any) => any, ...args: any[]) => {

        let waitTime = 0;

        if (infinite) {
            waitTime = 5000;
        } else {
            attempt++;
            if (attempt >= retries) {
                attempt = 0;
                throw new Error(`API call failed after ${retries} attempts.`);
            }

            // Calculate the exponential delay
            waitTime = initialDelay * Math.pow(2, attempt);
        }

        // Wait for the calculated time before retrying
        await new Promise(resolve => setTimeout(resolve, waitTime));

        apiCall(...args);
    };

    return retry;
};

export async function exponentialBackoff<T>(
    asyncFunction: () => Promise<T>,
    options: ExponentialBackoffOptions
): Promise<T> {
    const {
        retries = 5,
        initialDelay = 1000,
        maxDelay = Infinity,
        factor = 2,
        onRetry,
    } = options;

    let attempt = 0;
    let delay = initialDelay;

    while (attempt <= retries) {
        try {
            return await asyncFunction();
        } catch (error: any) {
            if (attempt === retries) {
                throw chainError(`Operation failed after multiple retry attempts`, error.message);
            }

            // Call the onRetry callback, if provided
            if (onRetry) {
                onRetry(attempt, error);
            }

            await new Promise((resolve) => setTimeout(resolve, delay));

            delay = Math.min(delay * factor, maxDelay);
        }

        attempt++;
    }

    // This point should not be reachable due to the retries limit
    throw new Error("Unexpected error: Retry loop exited incorrectly.");
}

export const generateBaShareLink = (baId: string) => {
    const serverUrl = environment.SERVER_URL
    const url = serverUrl + `/ba/${baId}`

    return url
};
