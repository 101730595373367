<template>
  <hzba-base-modal
    :title="fragenblock && fragenblock.getTitle()"
    :open="open"
    :ask-before-close="false"
    :go-back-mode="true"
    @close="() => cancel()"
  >
    <ion-content>
      <section-wrapper
        v-if="!surveyMapModal && fragenblock?.path"
        class="mt-4 mx-6 text-xs"
        style="color: #7f7f7f"
      >
        {{ getNestedBreadcrumbTitle(fragenblock.path) }}
      </section-wrapper>
      <section-wrapper>
        <RecursiveForm
          v-if="!surveyMapModal && fragenblock"
          :parent-fragenblock="fragenblock"
          :modal-depth="modalDepth"
        />
        <HzbaForm
          v-if="surveyMapModal"
          :read-only="!isAllowedToEdit"
          :form-frages="fragenblock?.frages"
        />

        <div>
          <div v-if="surveyMapModal && isAllowedToEdit">
            <SurveyMapFragenblockButtons
              @delete="deleteInstance()"
            />
          </div>
          <div v-else-if="!surveyMapModal">
            <hzba-delete-duplicate-item
              v-if="fragenblock && fragenblock.blockTyp && fragenblock.blockTyp.toLowerCase() === 'mehrfach'"
              :fragenblock="fragenblock"
              @delete="deleteInstance()"
              @duplicate="duplicateInstance()"
            />
          </div>
        </div>
      </section-wrapper>
    </ion-content>
  </hzba-base-modal>
</template>


<script lang="ts">

import HzbaBaseModal from "@/components/Base/BaseModal";
import HzbaGroup from "@/components/hzba/Base/HzbaGroup";
import HzbaBaseInput from "@/components/hzba/Base/Input/HzbaBaseInput";
import { openHzbaModal } from "@/utilities/modal-helper";
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from 'vue-i18n';

import SectionWrapper from "@/components/Base/SectionWrapper.vue";
import HzbaForm from "@/components/hzba/Base/HzbaForm.vue";
import HzbaDeleteDuplicateItem from "@/components/hzba/Base/Input/HzbaDeleteDuplicateItem.vue";
import HzbaModalItemBlock from "@/components/hzba/Base/Input/HzbaModalItemBlock.vue";
import RecursiveForm from "@/components/hzba/RecursiveForm.vue";
import SurveyMapFragenblockButtons from '@/components/v2/Map/SurveyMapFragenblockButtons.vue';
import {
    getNestedBreadcrumbTitles,
    useIdentifierFragenblockFinder
} from "@/composables/Bestandsaufnahme/useIdentifierFinder";
import useUser from "@/composables/useUser";
import { Fragenblock } from "@/models/ba/Fragenblock";
import { store } from "@/store/store";
import { IonContent, modalController } from "@ionic/vue";

export default defineComponent({
  name: "FragenblockModal",
  components: {
    RecursiveForm,
    HzbaDeleteDuplicateItem,
    SurveyMapFragenblockButtons,
    HzbaModalItemBlock,
    HzbaForm,
    HzbaBaseInput, HzbaGroup, HzbaBaseModal, IonContent, SectionWrapper },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    fragenblockBase: {
      type: Object as PropType<Fragenblock>,
      required: true,
    },
    modalDepth: {
      type: Number,
      default: 0,
    },
    surveyMapModal: {
      type: Boolean,
      required: false
    }
  },
  emits: ["close"],
  setup(props: any) {
    console.log("setup fragenblock modal")
    const { t } = useI18n({ useScope: 'global' });
    const { user } = useUser();

    const currentBa = computed(() => {
      return store.state.currentHzba.currentBa;
    })

    const fragenblock = computed<Fragenblock | undefined>(() => {
       return currentBa.value && useIdentifierFragenblockFinder(currentBa.value, props.fragenblockBase.path, undefined);
    })

    const isAllowedToEdit = computed(() => {
      // flag only matters atm for surveyMapModal
      return !props.surveyMapModal || (user.value?.id && fragenblock.value?.config?.userId === user.value.id);
    });

    function getFrages(fragenBlock: Fragenblock) {
      return fragenBlock.frages;
    }

    async function cancel() {
      await modalController.dismiss();
    }

    async function deleteInstance() {
      const copiedFragenblock = await fragenblock.value?.toClassJson();

      await modalController.dismiss({
        action: 'DELETE',
        data: copiedFragenblock,
      })
    }

    async function saveInstance() {
      const copiedFragenblock = await fragenblock.value?.toClassJson();

      await modalController.dismiss({
        action: 'SAVE',
        data: copiedFragenblock,
      })
    }

    async function duplicateInstance() {
      const copiedFragenblock = await fragenblock.value?.toClassJson();

      await modalController.dismiss({
        action: 'DUPLICATE',
        data: copiedFragenblock,
      })
    }

    const getNestedBreadcrumbTitle = (path: string) => {
      console.log("PATHH :    ",path )
      return path && currentBa.value && getNestedBreadcrumbTitles(currentBa.value, path);
    }

    return {
      fragenblock,
      currentBa,
      t,
      getNestedBreadcrumbTitle,
      getFrages,
      cancel,
      deleteInstance,
      saveInstance,
      duplicateInstance,
      openHzbaModal,
      isAllowedToEdit
    };
  },
});
</script>