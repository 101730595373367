<template>
  <ion-item
    :class="{ statusFilterButton: uiMode === 'propertyList', mapFilterButton: uiMode === 'map' }"
    lines="none"
  >
    <ion-select
      interface="popover"
      :placeholder="t('statusFilter.placeholder')"
      :multiple="true"
      :value="selectedStatuses"
      class="dynamic-width-select"
      @ionChange="onChanged"
    >
      <ion-select-option
        v-for="item in props.options"
        :key="item.value"
        :value="item.value"
      >
        {{ item.label }}
      </ion-select-option>
    </ion-select>
  </ion-item>
</template>

<script setup lang="ts">
import { IonItem, IonSelect, IonSelectOption } from '@ionic/vue';
import { PropType } from 'vue';
import { useI18n } from 'vue-i18n';
//Interfaces & Enums
import { ImmobilieStatus } from '@/models/immobilie/interfaces/IImmobilie';

const emit = defineEmits([
  "update:statusFilter"
])
const props = defineProps({
  options: { type: Array as PropType<Array<{ label: string, value: ImmobilieStatus }>>, required: true },
  selectedStatuses: { type: Array as PropType<Array<{ label: string, value: ImmobilieStatus }>>, required: false },
    uiMode: { type: String, required: true }, 
})

//Data
const { t } = useI18n({ useScope: "global" });

//Methods
function onChanged(e: any) {
  emit('update:statusFilter', e.detail.value)
}
</script>

<style lang="scss">

.statusFilterButton {
  --background: transparent;
  --border-radius: 16px;
  --color: var(--primary);
  --width: auto;
  --border-color: var(--primary);
  --border-style: solid;
  --border-width: 1px;
  font-size: 13px;
  --min-height: 28px;
}
.statusFilterButton::part(native) {
  border-bottom-color: var(--primary);
}
ion-checkbox {
  --border-color: var(--primary);
  --border-radius: 6px;
  --border-style: solid;
  --border-width: 2px;
}

ion-checkbox.checkbox-checked::part(container) {
  background: var(--primary);
  border-color: var(--primary);
}
ion-select {
  padding-top: 2px;
  padding-bottom: 2px;
}

.dynamic-width-select {
  max-width: var(--filter-width, 120px); 
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
