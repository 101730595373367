<template>
  <ion-page>
    <IonContent>
      <img
        src="../../public/assets/img/svg/acon_logo_colored.svg"
        alt="acon_icon"
        class="m-4 w-48"
      >

      <div class="login">
        <div class="form_group">
          <h1 class="acon-red">
            {{ t("registrierung.titel") }}
          </h1>
          <form
            novalidate
            @submit.prevent="onSubmit"
          >
            <AInput
              v-model="signupForm.username.$model"
              :errors="signupForm.username.$errors"
              :label="t('registrierung.name')"
              input-type="text"
            />

            <AInput
              v-model="signupForm.organisation.$model"
              :errors="signupForm.organisation.$errors"
              :label="t('registrierung.firma')"
              input-type="text"
            />

            <ion-item>
              <ion-label>{{ t("registrierung.position") }}</ion-label>
              <ion-select
                v-model="signupForm.position.$model"
                ok-text="Okay"
                cancel-text="Abbrechen"
              >
                <ion-select-option value="immobilienverwalter">
                  {{ t("registrierung.positionImmobilienverwalter") }}
                </ion-select-option>
                <ion-select-option value="gutachter">
                  {{ t("registrierung.positionGutachter") }}
                </ion-select-option>
              </ion-select>
            </ion-item>

            <AInput
              v-model="signupForm.telefonnummer.$model"
              :errors="signupForm.telefonnummer.$errors"
              :label="t('registrierung.telefonnummer')"
              input-type="text"
            />


            <AInput
              v-model="signupForm.email.$model"
              :errors="signupForm.email.$errors"
              :label="t('registrierung.email')"
              :placeholder="'mac@acon-energie.de'"
              input-type="email"
            />

            <AInput
              v-model="signupForm.password.$model"
              :errors="signupForm.password.$errors"
              :label="t('registrierung.passwort')"
              :placeholder="'Testpasswort01!'"
              input-type="password"
            />

            <AInput
              v-model="signupForm.confirmPassword.$model"
              :errors="signupForm.confirmPassword.$errors"
              :label="t('registrierung.passwortBestaetigen')"
              input-type="password"
            />

            <AButton
              type="submit"
              style="margin-top: 32px !important; margin-left: 0"
              blue-solid
            >
              {{ t("buttons.registrieren") }}
            </AButton>

            <router-link to="/login">
              <ion-button
                fill="clear"
                class="block"
                style="margin-top: 32px !important; margin-left: 0"
              >
                {{ t("registrierung.zurAnmeldung") }}
              </ion-button>
            </router-link>

            <offline-hint>{{ t("alerts.keinInternet") }}</offline-hint>
          </form>
        </div>
      </div>

      <FooterComp />
    </IonContent>
  </ion-page>
</template>

<script>
import AButton from "@/components/Base/AButton";
import AInput from "@/components/Base/AInput";
import FooterComp from "@/components/FooterComp";
import Toolbar from "@/components/Navigation/Toolbar";
import OfflineHint from "@/components/Other/OfflineHint";
import WelcomeAnimation from "@/components/Other/WelcomeAnimation";
import useAlert from "@/composables/useAlert";
import useAuth from "@/composables/useAuth";
import useFetchData from "@/composables/useFetchData";
import { useStore } from "@/composables/useTypedStore";
import { Monitoring } from '@/utilities/monitoring';
import {
    IonContent,
    IonItem,
    IonLabel,
    IonPage,
    IonSelect,
    IonSelectOption,
    onIonViewWillEnter
} from "@ionic/vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers, minLength, required, sameAs } from "@vuelidate/validators";
import { defineComponent, ref } from "vue";
import { useI18n } from 'vue-i18n';
import { useRouter } from "vue-router";

  export default defineComponent ({
    name: "Signup",
    components: {
      OfflineHint,
      WelcomeAnimation,
      Toolbar,
      FooterComp,
      AButton,
      AInput,
      IonPage,
      IonContent,
      IonSelect,
      IonSelectOption,
      IonItem,
      IonLabel,

    },
    setup() {
      const { t } = useI18n({ useScope: 'global' })
      const router = useRouter();
      const auth = useAuth();
      const alert = useAlert();
      const store = useStore();
      const useData = useFetchData();

      const username = ref('');
      const organisation = ref('');
      const position = ref('');
      const telefonnummer = ref('');
      const email = ref('');
      const password = ref('');
      const confirmPassword = ref('');
      const save = ref('');

      const rules = {
        username: {
          required: helpers.withMessage(t("helper.nameErforderlich"), required),
        },
        organisation: {
          required: helpers.withMessage(t("helper.firmaErforderlich"), required),
        },
        position: {
          required: helpers.withMessage(t("helper.positionErforderlich"), required),
        },
        telefonnummer: {
          required: helpers.withMessage(t("helper.telefonErforderlich"), required),
        },
        email: {
          required: helpers.withMessage(t("helper.emailErforderlich"), required),
          email: helpers.withMessage(t("helper.emailUngueltig"), email),
        },
        password: {
          required: helpers.withMessage(t("helper.passwortErforderlich"), required),
          minLength: helpers.withMessage(t("helper.passwortLaenge"), minLength(8)),
          goodPassword: helpers.withMessage(t("helper.passwortSicherheit"), (password) => {
            return password.length >= 6 &&
                (/[a-z]/.test(password) || /[A-Z]/.test(password)) && /[0-9]/.test(password)
          })
        },
        confirmPassword: {
          required: helpers.withMessage(t("helper.passwortBestaetigung"), required),
          sameAsPassword: helpers.withMessage(t("helper.passwortNichtIdent"), sameAs(password))
        },
        save: {},
      };

      const signupForm = useVuelidate(rules, {
        username,
        organisation,
        position,
        telefonnummer,
        email,
        password,
        confirmPassword,
        save,
      });

      const onSubmit = async () => {
        // signupForm.value.$touch();

        // if (signupForm.value.$invalid) {
        //   console.log('Login form invalid:', signupForm.value.position.$model.value);
        //   return;
        // }

        try {
          const signupData = {
            username: username.value,
            organisation: organisation.value,
            position: position.value,
            telefonnummer: telefonnummer.value,
            email: email.value,
            password: password.value,
            confirmPassword: confirmPassword.value,
            save: save.value,
          }

          await auth.signup(signupData);
          // await auth.signup({
          //   username: signupData.username, // forename + surname, must be unique
          //   organisation: "Test Org",
          //   position: "Test",
          //   telefonnummer: "098765",
          //   email: "email@email.de",
          //   password: "pw"
          // })
          await useData.fetch();
          await router.push({ path: '/' });
        } catch (err) {
          if (err.status === 'Network Error') {
            await alert.show(t("alerts.keinInternetHead"),t("alerts.keinInternetText"));
          } else if (err.response?.data.message[0].messages[0].id === "Auth.form.error.email.taken") {
            await alert.show(t("alerts.emailVergebenHead"), t("alerts.emailVergebenText"));
            password.value = '';
          } else if (err.response?.status === 400) {
            await alert.show(t("alerts.registrierungfehlgeschlagenHead"), t("alerts.registrierungfehlgeschlagenText"));
            password.value = '';
          } else {
            await alert.show(t("alerts.unerwarteterFehlerHead"), t("alerts.unerwarteterFehlerText"));
            Monitoring.chainError('Login fehler', err);
          }
        }
      };

      onIonViewWillEnter(() => {
        store.commit('app/hideBottomNavigation')
      });

      return {
        signupForm,
        password, confirmPassword,
        onSubmit,
        t
      }
    }
  })
</script>

<style scoped lang="scss">

  .login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .form_group {
    top: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 64px;
    bottom: 0;

    @media (max-height: 900px) {
      position: static;
    }
  }

</style>