import CreateSurveyApi from "@/api/CreateSurveyApi";

export interface CreateSurveyDateInterface {
  type: String;
  property: String;
  date: String;
  name: String,
  userId: String;
}

export function useCreateSurvey() {
  async function fetchTemplates() {
    const templates = (await (CreateSurveyApi.getTemplates())).data.data
    return templates
  }

  async function sendDataToBackend(body: any) {
    try {
      const response = await CreateSurveyApi.postSurvey(body)
      return response
    } catch(err: any) {
      console.log(err)
    }
  }

  return {
    fetchTemplates,
    sendDataToBackend
  }
}
