import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35acaa60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pdf-content" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_counter_start = _resolveComponent("page-counter-start")!
  const _component_mast_uebersicht = _resolveComponent("mast-uebersicht")!
  const _component_mast_meldung = _resolveComponent("mast-meldung")!
  const _component_new_vation5_pictures = _resolveComponent("new-vation5-pictures")!
  const _component_mast_zugaenglichkeit = _resolveComponent("mast-zugaenglichkeit")!
  const _component_mast_notizen = _resolveComponent("mast-notizen")!
  const _component_hinweis = _resolveComponent("hinweis")!
  const _component_page_counter_end = _resolveComponent("page-counter-end")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_page_counter_start),
    _createVNode(_component_mast_uebersicht, {
      ba: _ctx.ba,
      immobilie: _ctx.immobilie,
      meldungen: _ctx.meldungen,
      lang: _ctx.lang
    }, null, 8, ["ba", "immobilie", "meldungen", "lang"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.meldungen, (meldung, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        (index != 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_mast_meldung, {
                meldung: meldung,
                immobilie: _ctx.immobilie,
                ba: _ctx.ba,
                lang: _ctx.lang,
                index: index
              }, null, 8, ["meldung", "immobilie", "ba", "lang", "index"]),
              (meldung?.frages?.[5].eingabeMedien[0]!= null)
                ? (_openBlock(), _createBlock(_component_new_vation5_pictures, {
                    key: 0,
                    header: 'Fotos des Schadens',
                    lang: _ctx.lang,
                    "image-objects": meldung?.frages?.[5].eingabeMedien ?? [],
                    ba: _ctx.ba,
                    immobilie: _ctx.immobilie
                  }, null, 8, ["lang", "image-objects", "ba", "immobilie"]))
                : _createCommentVNode("", true),
              (meldung?.frages?.[11].eingabeMedien[0]!= null)
                ? (_openBlock(), _createBlock(_component_new_vation5_pictures, {
                    key: 1,
                    header: 'Fotos der Maßnahme',
                    lang: _ctx.lang,
                    "image-objects": meldung?.frages?.[11].eingabeMedien ?? [],
                    ba: _ctx.ba,
                    immobilie: _ctx.immobilie
                  }, null, 8, ["lang", "image-objects", "ba", "immobilie"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    _createVNode(_component_mast_zugaenglichkeit, {
      zugaenglichkeiten: _ctx.zugaenglichkeiten,
      immobilie: _ctx.immobilie,
      ba: _ctx.ba,
      lang: _ctx.lang
    }, null, 8, ["zugaenglichkeiten", "immobilie", "ba", "lang"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.zugaenglichkeiten, (zugang, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        (zugang?.fragenblocks?.[0].frages?.[3].eingabeMedien?.[0] != null)
          ? (_openBlock(), _createBlock(_component_new_vation5_pictures, {
              key: 0,
              header: 'Notiz '+ index.toString(),
              lang: _ctx.lang,
              "image-objects": zugang?.fragenblocks?.[0].frages?.[3].eingabeMedien ?? [],
              ba: _ctx.ba,
              immobilie: _ctx.immobilie
            }, null, 8, ["header", "lang", "image-objects", "ba", "immobilie"]))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    _createVNode(_component_mast_notizen, {
      notizen: _ctx.notizen,
      immobilie: _ctx.immobilie,
      ba: _ctx.ba,
      lang: _ctx.lang
    }, null, 8, ["notizen", "immobilie", "ba", "lang"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notizen, (notiz, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        (notiz?.fragenblocks?.[0].frages?.[3].eingabeMedien?.[0] != null)
          ? (_openBlock(), _createBlock(_component_new_vation5_pictures, {
              key: 0,
              header: 'Notiz '+ index.toString(),
              lang: _ctx.lang,
              "image-objects": notiz?.fragenblocks?.[0].frages?.[3].eingabeMedien ?? [],
              ba: _ctx.ba,
              immobilie: _ctx.immobilie
            }, null, 8, ["header", "lang", "image-objects", "ba", "immobilie"]))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    _createVNode(_component_hinweis, {
      hinweise: _ctx.hinweise,
      immobilie: _ctx.immobilie,
      ba: _ctx.ba,
      lang: _ctx.lang
    }, null, 8, ["hinweise", "immobilie", "ba", "lang"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hinweise, (hinweis, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        (hinweis?.frages?.[1].eingabeMedien[0]!= null)
          ? (_openBlock(), _createBlock(_component_new_vation5_pictures, {
              key: 0,
              header: 'Hinweis '+ index.toString(),
              lang: _ctx.lang,
              "image-objects": hinweis?.frages?.[1].eingabeMedien ?? [],
              ba: _ctx.ba,
              immobilie: _ctx.immobilie
            }, null, 8, ["header", "lang", "image-objects", "ba", "immobilie"]))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    _createVNode(_component_page_counter_end)
  ]))
}