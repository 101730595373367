<template>
  <ion-header>
    <toolbar
      :title="t('hzba.vorschau.bescheinigungTitel')"
      :enable-desktop-nav="false"
    >
      <template #trailing>
        <ion-buttons slot="start">
          <ion-button
            data-cy="photo-preview-close"
            @click="close()"
          >
            <ion-icon
              slot="icon-only"
              class="white-icon"
              :icon="arrowBack"
            />
          </ion-button>
        </ion-buttons>
      </template>
    </toolbar>
  </ion-header>

  <ion-content>
    <div class="m-4">
      <div>
        <!-- TODO: refactor component -->
        <signature-wrapper
          ref="signatureWrapper"
          :ba="ba"
          :is-readonly="isReadonly"
          @onSignaturesComplete="onSignaturesComplete"
        />
      </div>


      <div class="mt-12 ml-6 mr-6 mb-12">
        <p v-if="!isReadonly && ba?.unterschrifts?.length !== 0">
          {{ t("hzba.vorschau.bescheinigungInfo") }}
        </p>
        <p v-if="!isReadonly && ba?.unterschrifts?.length === 0">
          {{ t("hzba.vorschau.unterschriftLoeschen") }}
        </p>

        <p v-if="isReadonly && (ba.status === 'ABGESCHLOSSEN')">
          {{ t("hzba.vorschau.hzbaAbgeschlossenInfo") }}
        </p>
        <p v-if="isReadonly && !(ba.status === 'ABGESCHLOSSEN')">
          {{ t("hzba.vorschau.unterschriftInfoReadOnly") }}
        </p>

        <AButton
          v-if="!isReadonly"
          class="text-lg py-5 px-8 mr-3"
          :btn-primary="true"
          shape="round"
          :disabled="!isSignatureComplete"
          @click="submitHzba"
        >
          {{ t("hzba.buttons.bestaetigen") }}
        </AButton>
        <AButton
          v-if="!isReadonly"
          class="text-lg py-5 px-8"
          :btn-primary="true"
          fill="flat"
          @click="addSignature"
        >
          {{ t("hzba.buttons.unterschriftHinzufuegen") }}
        </AButton>
      </div>
    </div>
  </ion-content>
</template>


<script lang="ts">
import AButton from "@/components/Base/AButton";
import HzbaBaseModal from "@/components/Base/BaseModal";
import SectionWrapper from "@/components/Base/SectionWrapper.vue";
import Toolbar from "@/components/Navigation/Toolbar.vue";
import SignatureWrapper from "@/components/Signature/SignatureWrapper.vue";
import HzbaGroup from "@/components/hzba/Base/HzbaGroup";
import PhotoViewModal from "@/components/hzba/Base/HzbaPhotoViewModal.vue";
import HzbaBaseInput from "@/components/hzba/Base/Input/HzbaBaseInput";
import HzbaChooseInput from "@/components/hzba/Base/Input/HzbaChooseInput.vue";
import HzbaPhotoInput from "@/components/hzba/Base/Input/HzbaPhotoInput.vue";
import { UploadBaPhotos } from "@/composables/Upload/UploadBaPhotos";
import { useStore } from "@/composables/useTypedStore";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import { Network } from "@capacitor/network";
import { IonButton, IonButtons, IonChip, IonContent, IonHeader, IonIcon, modalController } from "@ionic/vue";
import { arrowBack } from "ionicons/icons";
import { PropType, computed, defineComponent, ref } from "vue";
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: "SignAndSubmitModal",
  components: {
    AButton,
    SignatureWrapper,
    HzbaChooseInput,
    HzbaPhotoInput,
    PhotoViewModal,
    IonButtons,
    IonButton,
    IonChip,
    Toolbar, HzbaBaseInput, HzbaGroup, HzbaBaseModal, SectionWrapper, IonIcon, IonContent, IonHeader },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
  },
  emits: ["close", "save"],
  setup(props: any) {
    const { t } = useI18n({ useScope: 'global' })
    const store = useStore();

    const unterschriftsBackup = JSON.parse(JSON.stringify(props.ba.unterschrifts));

    const isSignatureComplete = ref(false);
    const signatureWrapper = ref()


    const currentProject = computed(() => store.state.user.currentUserProject);

    function onSignaturesComplete(bool: boolean) {
      isSignatureComplete.value = bool;
    }

    const submitHzba = async () => {
      store.dispatch('currentHzba/saveHzbaLocally');
      const currentBa = store.state.currentHzba.currentBa;
      const networkStatus = await Network.getStatus();

      if ( networkStatus.connected ) {
        store.commit('currentHzba/setPhotoUploadTasks', new UploadBaPhotos());
        const photoUploadTasks = store.state.currentHzba.photoUploads;
        photoUploadTasks.getPhotoUploadTasks(currentBa, currentBa.immobilie);

        const copiedBa = await store.state.currentHzba.currentBa.copyLeanJson({prepareForSync: true});
        const surveyResp = await BestandsaufnahmeModel.api().put(`/bestandsaufnahmes/${copiedBa?.id}?projectId=${currentProject.value.id}`,
            {data: copiedBa},
            {save: false},
        );

        const updatedBaJsonRes = (surveyResp.getDataFromResponse() as any).data;
        updatedBaJsonRes.hasUnsavedChanges = false;
        const updatedBa = new Bestandsaufnahme(updatedBaJsonRes, undefined);

        photoUploadTasks.reassignPhotosToBa(updatedBa);
        updatedBa.isLocal = false;
        updatedBa.hasLocalImages = true;

        const imageUploadRes = await photoUploadTasks.uploadPhotos(updatedBa, store);
        const imageUploadSuccess = imageUploadRes.success;

        updatedBa.hasLocalImages = !imageUploadSuccess;
        store.commit('currentHzba/setCurrentBa', updatedBa);
        await store.dispatch("currentHzba/saveHzbaLocally");

        const updatedBaJson = await updatedBa.toClassJson() // TODO REFACT: likely not necessary
        await BestandsaufnahmeModel.dispatch('storeMoverSurvey', updatedBaJson ); // TODO REFACT: likely not necessary
      }

      await modalController.dismiss();
    }

    const addSignature = () => {
      signatureWrapper.value.addSignature()
    }

    const close = async () => {
      props.ba.unterschrifts = unterschriftsBackup;
      await modalController.dismiss();
    };

    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );

    return {
      addSignature,
      close,
      submitHzba,
      onSignaturesComplete,
      isSignatureComplete,
      // signatureComplete,
      signatureWrapper,
      isReadonly,
      arrowBack,
      t
    }
  },
});
</script>

<style lang="scss">
.mangel-item {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding-top: 8px;
  padding-bottom: 8px;
}

.white-icon {
  color: var(--white100);
}
</style>