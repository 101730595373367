import CreatePropertyApi from "@/api/CreatePropertyApi";
import { useProperties } from "@/composables/Property/useProperties";
import { useStore } from "@/composables/useTypedStore";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import Immobilie from "@/models/immobilie.model";
import { Network } from "@capacitor/network";
import { computed } from "vue";

export async function handleOfflineProperties() {
  const store = useStore();
  const offlineProperties = computed(() => Immobilie.getters("persistedProperties"))
  const networkState = await Network.getStatus()
  const currentProject = computed(() => store.state.user.currentUserProject)
  const { loadImmobilienPreviews, uploadPropertyVorschaubild } = useProperties();

  if(networkState.connected && offlineProperties.value && offlineProperties.value.length ) {
    let redirect = null;

    for(let i=0; i<offlineProperties.value.length; i++ ) {
      if (offlineProperties.value[i].hasUnsavedChanges ) {
        const body = {
          name: offlineProperties.value[i].name,
          strasse: offlineProperties.value[i].strasse,
          plz: offlineProperties.value[i].plz,
          stadt: offlineProperties.value[i].stadt,
          stadtbezirk: offlineProperties.value[i].stadtbezirk,
          externeObjektNr: offlineProperties.value[i].externeObjektNr,
          baujahr: offlineProperties.value[i].baujahr,
          geolocationLat: offlineProperties.value[i].geolocationLat ? offlineProperties.value[i].geolocationLat : null,
          geolocationLon: offlineProperties.value[i].geolocationLon ? offlineProperties.value[i].geolocationLon : null,
          projects: {
            disconnect: [],
            connect: [
              {
                id: currentProject.value.id,
                position: {
                  end: true
                }
              }
            ]
          }
        }

        if(offlineProperties.value[i].updatedAt) {
          try {
            await Immobilie.api().put(`/immobilies/${offlineProperties.value[i].id}?projectId=${currentProject.value.id}`, {
              save: true,
              data: {
                name: body.name,
                strasse: body.strasse,
                plz: body.plz,
                stadt: body.stadt,
                stadtbezirk: body.stadtbezirk,
                externeObjektNr: body.externeObjektNr,
                baujahr: body.baujahr,
                geolocationLat: body.geolocationLat,
                geolocationLon: body.geolocationLon
              }
            })

            const immobilieObject = offlineProperties.value[i]
            immobilieObject.hasUnsavedChanges = false
            await Immobilie.dispatch('$deleteFromLocal', offlineProperties.value[i].id);
            await Immobilie.dispatch('$updateLocally', { data: immobilieObject });
          } catch(error: any) {
            console.log(error)
          }
        } else {
          const response = await CreatePropertyApi.postProperty(body)
          if(response.status === 200) {
            const offlineId = offlineProperties.value[i].id;
            await handleSurveys(offlineId, response.data.data.id)
            //First upload images
            if (offlineProperties.value[i].vorschaubild && offlineProperties.value[i].vorschaubild.length > 0) {
              await uploadPropertyVorschaubild(response.data.data, offlineProperties.value[i].vorschaubild)
            }
            //Then update indexDB
            const immobilieObject = {
              id: response.data.data.id,
              isDownloaded: true,
              ...response.data.data.attributes,
            }
            immobilieObject.hasUnsavedChanges = false
            await Immobilie.dispatch('$deleteFromLocal', offlineId);
            await Immobilie.dispatch('$updateLocally', { data: immobilieObject });
            if (store.state.app.currentRoute.name === "property-detail" && store.state.app.currentPropertyId === offlineId) {
              // if user is on the detail page of the offline-created property, redirect to the newly db-created property
              redirect = `/property/${response.data.data.id}`;
            }
          }
        }
      }
    }
    // TODO: find out why loadAllProperties was present here before.
    await loadImmobilienPreviews()
    
    if(redirect) {
      store.state.app.router?.push(redirect);
    }
  }
}

async function handleSurveys(offlinePropertyId: any, onlinePropertyId: any) {
  const offlineSurveys = BestandsaufnahmeModel.getters("persistedBestandsaufnahmes")
  const filteredSurveys = offlineSurveys.filter((item: Bestandsaufnahme) => !isFullNonDecimalNumber(item.id))
  for(let i=0; i<filteredSurveys.length;i++) {
    if(filteredSurveys[i].immobilie === offlinePropertyId) {
      const updatedBa = filteredSurveys[i];
      updatedBa.immobilie = onlinePropertyId;
      await BestandsaufnahmeModel.dispatch('storeMoverSurvey', updatedBa );
    }
  }
}

export function isFullNonDecimalNumber(id: any) {
  const isDigitsOnly = /^\d+$/.test(id);
  const isInteger = Number.isInteger(Number(id));
  return isDigitsOnly && isInteger;
}
