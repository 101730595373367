import { Map } from "maplibre-gl";

class ExtendDrawBar {
  draw: any;
  drawOptions: any;
  buttons: any[];
  onAddOrig: any;
  onRemoveOrig: any;
  map: any;
  elContainer: any;

  constructor(opt: any) {
    this.draw = opt.draw;
    this.drawOptions = opt.drawOptions;
    this.buttons = opt.buttons || [];
    this.onAddOrig = opt.draw.onAdd;
    this.onRemoveOrig = opt.draw.onRemove;
  }
  onAdd(map: Map) {
    this.map = map;
    this.elContainer = this.onAddOrig(map);
    this.buttons.forEach((b) => {
      this.addButton(b);
    });
    return this.elContainer;
  }
  onRemove(map: Map) {
    this.buttons.forEach((b) => {
      this.removeButton(b);
    });
    this.onRemoveOrig(map);
  }
  addButton(opt: any) {
    const defaultControl = 'displayControlsDefault' in this.drawOptions ? this.drawOptions.displayControlsDefault : true;
    const controlExists = this.drawOptions && this.drawOptions.controls && opt.controlName in this.drawOptions.controls;
    if ((controlExists && this.drawOptions.controls[opt.controlName]) || (!controlExists && defaultControl)) {
      const elButton = document.createElement("button");
      elButton.className = "mapbox-gl-draw_ctrl-draw-btn";
      if (opt.classes instanceof Array) {
        opt.classes.forEach((c: any) => {
          elButton.classList.add(c);
        });
      }
      elButton.setAttribute("title", opt.title);
      elButton.addEventListener(opt.on, opt.action);
      this.elContainer.appendChild(elButton);
      opt.elButton = elButton;
    }
  }
  removeButton(opt: any) {
    if (opt.elButton) {
      opt.elButton.removeEventListener(opt.on, opt.action);
      opt.elButton.remove();
    }
  }
}

export { ExtendDrawBar };