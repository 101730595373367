<template>
  <ion-item
    :class="{ personFilterButton: uiMode === 'propertyList', mapFilterButton: uiMode === 'map' }"
    lines="none"
  >
    <ion-select
      interface="popover"
      :placeholder="t('personFilter.placeholder')"
      :multiple="true"
      :value="selectedPersons"
      class="dynamic-width-select"
      @ionChange="onChanged"
    >
      <ion-select-option
        v-for="item in props.options"
        :key="item.id"
        :value="item.username"
      >
        {{ item.username }}
      </ion-select-option>
    </ion-select>
  </ion-item>
</template>

<script setup lang="ts">
import { IonItem, IonSelect, IonSelectOption } from '@ionic/vue';
import { PropType } from 'vue';
import { useI18n } from 'vue-i18n';
//Models
import User from '@/models/user';

const emit = defineEmits([
  "update:personFilter"
])
const props = defineProps({
  options: { type: Array as PropType<Array<User>>, required: true },
  selectedPersons: { type: Array as PropType<Array<User>>, required: true },
    uiMode: { type: String, required: true }, 
})

//Data
const { t } = useI18n({ useScope: "global" });

//Methods
function onChanged(e: any) {
  emit('update:personFilter', e.detail.value)
}
</script>

<style lang="scss" scoped>
.personFilterButton {
  --background: transparent;
  --border-radius: 16px;
  --color: var(--primary);
  --width: auto;
  --border-color: var(--primary);
  --border-style: solid;
  --border-width: 1px; 
  font-size: 13px;
  --min-height: 28px;
}
.personFilterButton::part(native) {
  border-bottom-color: var(--primary);
}
ion-select {
  padding-top: 2px;
  padding-bottom: 2px;
}
.dynamic-width-select {
  max-width: var(--filter-width, 120px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
