<template>
  <ion-page>
    <toolbar :title="t('toolbar.libraries')">
      <template #trailing>
        <UserDialogButton />
      </template>
    </toolbar>

    <ion-card>
      <ion-card-header class="py-0">
        <ion-card-title>
          <ion-text>
            <h1 class="pl-6 mb-0">
              {{ t("libraries.titel") }}
            </h1>
          </ion-text>
        </ion-card-title>
      </ion-card-header>
      <ion-card-content class="container">
        <ion-grid>
          <ion-row>
            <ion-col
              v-for="(library, index) in licenseReport"
              :key="index"
              size="6"
              size-xs="12"
              size-sm="12"
              size-md="6"
              size-lg="6"
              size-xl="6"
            >
              <ion-card class="adjust-size">
                <ion-card-title class="pt-5 pl-5 ion-text-left">
                  {{ library.name }}
                </ion-card-title>
                <ion-card-content>
                  <ul>
                    <li>
                      <strong>Version: </strong>
                      {{ library.installedVersion }}
                    </li>
                    <li><strong>Lizenz: </strong> {{ library.licenseType }}</li>
                    <li>
                      <strong>Link: </strong>
                      <a :href="licenseReport[index].link">{{
                        library.link
                      }}</a>
                    </li>
                  </ul>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
  </ion-page>
</template>

<script setup lang="ts">
import licenseReport from "@/components/Impressum/licenseReport.json";
import { useI18n } from "vue-i18n";
import Toolbar from "@/components/Navigation/Toolbar";
import {
  IonPage,
  onIonViewWillEnter,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonText,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";
import UserDialogButton from "@/components/User/UserDialogButton.vue";
import { useStore } from "@/composables/useTypedStore";

const { t } = useI18n({ useScope: "global" });
const store = useStore();

onIonViewWillEnter(() => {
  store.commit("app/showBottomNavigation");
});

</script>

<style scoped lang="scss">
* {
  box-sizing: border-box;
}

h1 {
  color: var(--black100);
  @media (max-width: 460px) {
    font-size: 22px;
    font-weight: bold;
    padding-left: 0;
    text-align: left;
  }
}

a {
  text-decoration: none;
}
.container {
  padding: 0;
}
ion-card {
  box-shadow: $boxShadow;
  overflow-y: scroll;
  margin-top: 0;
  @media (max-width: 460px) {
    padding: 0;
  }
}
ion-card-title {
  font-size: 1.2em;
  padding-top: 25px;
  padding-left: 25px;
}
ion-card.adjust-size {
  @media (max-width: 760px) {
    height: auto;
  }
  @media (min-width: 761px) {
    height: 200px;
  }
  @media (min-width: 1200px) {
    height: auto;
  }
}
ul {
    padding-left: 25px;
    margin: 0;
}

li {
  @media (max-width: 460px) {
    font-size: .95em;
  }
}

</style>