import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "px-4"
}
const _hoisted_2 = {
  key: 0,
  class: "text-2xl"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = {
  key: 4,
  class: "mt-6"
}
const _hoisted_7 = { class: "md:flex items-center" }
const _hoisted_8 = { class: "md:ml-2 mt-2 md:mt-0" }
const _hoisted_9 = {
  key: 5,
  class: "text-2xl"
}
const _hoisted_10 = {
  key: 6,
  class: "flex flex-col justify-between items-start",
  "data-cy": "info-uploadSuccess"
}
const _hoisted_11 = {
  key: 7,
  class: "text-2xl"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 2 }
const _hoisted_15 = { key: 8 }
const _hoisted_16 = { key: 9 }
const _hoisted_17 = {
  key: 10,
  "data-cy": "info-error-noInternet"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_IonButton = _resolveComponent("IonButton")!
  const _component_IonButtons = _resolveComponent("IonButtons")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, { class: "ion-padding" }, {
    default: _withCtx(() => [
      (_ctx.uploadStatus)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.isUploadActive)
              ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.t("hzba.vorschau.bestandsaufnahmeHochladen")), 1))
              : _createCommentVNode("", true),
            (_ctx.uploadStatus === 'preparing' || _ctx.uploadStatus === 'uploadingData')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.t("hzba.vorschau.bestandsaufnahmeHochladenBeschriebung")), 1),
                  _createVNode(_component_ion_progress_bar, { type: "indeterminate" })
                ]))
              : _createCommentVNode("", true),
            (_ctx.uploadStatus === 'uploadingImages')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("p", null, _toDisplayString(`${
              _ctx.uploadImageStatus.currentSuccessfulUploads() ?? "-"
            }/${_ctx.uploadImageStatus.currentTotalUploads() || "..."} ${_ctx.t(
              "hzba.vorschau.bilderHochladeStatus"
            )}`), 1),
                  _createVNode(_component_ion_progress_bar, {
                    value: _ctx.uploadImageStatus.currentUploadPercentage.value
                  }, null, 8, ["value"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.uploadStatus === 'uploadingPdfs')
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.t("hzba.vorschau.bestandsaufnahmeHochladenPdfUpload")), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.isUploadActive)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.t("hzba.vorschau.bestandsaufnahmeHochladenHinweis")), 1),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_ion_button, {
                        class: "mt-2 normal-case",
                        shape: "round",
                        disabled: _ctx.oneImageToUpload,
                        onClick: _ctx.abortSync
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Pausieren ")
                        ]),
                        _: 1
                      }, 8, ["disabled", "onClick"])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("strong", null, _toDisplayString(_ctx.oneImageToUpload ?
                'Synchronisierung wird abgeschlossen...' :
                _ctx.t("hzba.vorschau.bestandsaufnahmeHochladenNichtAbbrechen")), 1)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.isUploadSucceeded)
              ? (_openBlock(), _createElementBlock("h2", _hoisted_9, _toDisplayString(_ctx.t("hzba.vorschau.bestandsaufnahmeHochladenSucceeded")), 1))
              : _createCommentVNode("", true),
            (_ctx.isUploadSucceeded)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.isUploadSucceededSync
              ? _ctx.t("hzba.vorschau.bestandsaufnahmeErfolgSync")
              : _ctx.t("hzba.vorschau.bestandsaufnahmeErfolg")), 1),
                  (!_ctx.isUploadSucceededSync)
                    ? (_openBlock(), _createBlock(_component_IonButton, {
                        key: 0,
                        type: "button",
                        "data-cy": "button-uploadSuccess",
                        fill: "clear",
                        onClick: _ctx.confirm
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("hzba.buttons.ok")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (_ctx.isUploadSucceededSync && _ctx.isMaxXSmall)
                    ? (_openBlock(), _createBlock(_component_IonButton, {
                        key: 1,
                        class: "mt-2 normal-case",
                        shape: "round",
                        "data-cy": "button-weiterbearbeiten",
                        fill: "solid",
                        style: {"display":"inline"},
                        onClick: _ctx.confirm
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("hzba.buttons.weiterbearbeiten")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (_ctx.isUploadSucceededSync && !_ctx.isMaxXSmall)
                    ? (_openBlock(), _createBlock(_component_IonButtons, { key: 2 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_IonButton, {
                            "data-cy": "button-weiterbearbeiten",
                            type: "button",
                            onClick: _ctx.confirm
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t("hzba.buttons.weiterbearbeiten")), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.isUploadFailed)
              ? (_openBlock(), _createElementBlock("h2", _hoisted_11, [
                  (_ctx.uploadStatus === 'errorNoInternetConnection')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.t("hzba.vorschau.bestandsaufnahmeHochladenFailedNoConnection")), 1))
                    : (_ctx.uploadStatus === 'errorBackendDidNotRespond')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.t("hzba.vorschau.errorBackendDidNotRespond")), 1))
                      : (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_ctx.t("hzba.vorschau.bestandsaufnahmeHochladenFailed")), 1))
                ]))
              : _createCommentVNode("", true),
            (_ctx.uploadStatus === 'errorData')
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.t("hzba.vorschau.bestandsaufnahmeFehler")), 1),
                  _createVNode(_component_IonButton, {
                    type: "button",
                    onClick: _ctx.confirm
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("hzba.buttons.ok")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.uploadStatus === 'errorImage')
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.t("hzba.vorschau.bestandsaufnahmeFehlerBilder")), 1),
                  _createVNode(_component_IonButton, {
                    type: "button",
                    onClick: _ctx.confirm
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("hzba.buttons.ok")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.uploadStatus === 'errorNoInternetConnection' || _ctx.uploadStatus === 'errorBackendDidNotRespond')
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.uploadStatus === 'errorBackendDidNotRespond' ? _ctx.t("hzba.vorschau.errorBackendDidNotRespondDescription") : _ctx.t("hzba.vorschau.keinInternetLokalGespeichert")), 1),
                  _createVNode(_component_IonButton, {
                    type: "button",
                    fill: "clear",
                    "data-cy": "button-uploadFailed-ok",
                    onClick: _ctx.confirm
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("hzba.buttons.ok")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}