<template>
  <pdf-a4-page no-margin :pdf-key="lang" class="pdf-content" v-for="(hinweisblock, index) in splitIntoChunks(hinweise)" :key="index">
    <div class="report-background w-screen h-screen">
    <page-header :ba="ba" :immobilie="immobilie" :Title="'Hinweise'"/>
    <div class="flex flex-col justify-between">
      <div v-if="index === 0">
          <span class="pageHeader flex justify-between" style="margin-top: 5px;">Anmerkungen</span>
          <hr class="trenner-m"/>
      </div>
      <div :class="[index === 0  ? 'content' : 'content-long']">
        <div class="title-container">
          <div v-for="(hinweis, index2) in hinweisblock" :key="index2">
            <div v-if="hinweis != null" class="fragenBlock">
              <div class="smallHeader" data-cy="note-title">
                {{ hinweis.frages?.[0].eingabeText }}
              </div>
              <div class="textBlock" v-if="hinweis.frages?.[2].eingabeText !== ''" data-cy="note-description">
                {{ hinweis.frages?.[2].eingabeText }}
              </div>
              <div class="textBlock mt-2" v-else>
                {{ "Keine Beschreibung vorhanden" }}
              </div>
              <div class="mt-5 flex">
                <div class="ml-2 mr-2 mt-3">Fotos:</div>
                <div class="mt-0" data-cy="pdf-note-photos" v-if="hinweis.frages?.[1].eingabeMedien != null">
                  <PdfImageRow :imageObjects="getImageObjects(hinweis, 1)"/>
                </div> 
                <div class="mt-3" data-cy="pdf-note-photos" v-else>
                  Keine Fotos
                </div>          
              </div>
            <hr class="trenner"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <page-bottom/>
  </div>
  </pdf-a4-page>
</template>

<script lang="ts">
  import useUser from '@/composables/useUser';
  import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
  import { Fragenblock } from '@/models/ba/Fragenblock';
  import Immobilie from '@/models/immobilie.model';
  import { defineComponent, PropType } from 'vue';
  import { useI18n } from 'vue-i18n';
  import moment from 'moment';
  import { useIdentifierFrageFinder } from '@/composables/Bestandsaufnahme/useIdentifierFinder';
  import { getPhotoUrl } from '@/utilities/get-media-url';
  import PdfA4Page from '@/components/Pdf/Components/PdfA4Page.vue';
  import PageHeader from './PageHeader.vue';
  import PageBottom from './PageBottom.vue';
  import PdfImageRow from './NewVationImageRow.vue';

  export default defineComponent({
    name: 'AllgemeinPage',
    components: {
      PdfA4Page,
      PageHeader,
      PdfImageRow,
      PageBottom,
    },
    props: {
      ba: {
        type: Object as PropType<Bestandsaufnahme>,
        required: true,
      },
      hinweise: {
        type: Object as PropType<Fragenblock[]>,
        required: true,
      },
      immobilie: {
        type: Object as PropType<Immobilie>,
        required: true,
      },
      lang: {
        type: String,
        default: 'de',
      },
    },
    methods:{

      getImageObjects(hinweis: any, index: number) {
        return hinweis.frages?.[index].eingabeMedien ?? [];
      },
      splitIntoChunks(hinweise: any[]) {
        hinweise = hinweise.slice(1, hinweise.length);
        const result = [];
        for (let i = 0; i < hinweise.length; i += 3)
            result.push(hinweise.slice(i, i + 3));
        return result;
      }
    },
    setup(props: any) {
      const { t } = useI18n({ useScope: 'global' });
      const uUser = useUser();
      
      return {
        user: uUser.user,
        t,
        useIdentifierFrageFinder,
        moment,
        getPhotoUrl,
      };
    },
  });
</script>
<style scoped lang="scss">
  * {
    color: black;
    background-color: white;
    font-size: 12px;
  }
  .pdf-content {
    width: 800px;
    margin: 0 auto;
    color: var(--white100);
    height: 1100px;
  }
  .report-background {
    position: absolute;
    top: 50px;
    left: 50px;
    width: 700px;
    height: 1050px;
    color: var(--black100);
    margin-bottom: -20px;
  }
  .pageHeader {
    font-weight: bold;
    font-size: 20px;
    margin-top: -45px;
  }
  .midHeader {
    font-weight: bold;
    font-size: 15px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 5px;
  }
  .smallHeader {
    font-weight: bold;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 10px;
  }
  .content {
    height: 830px;
  }
  .content-long {
    height: 895px; 
  }
  .stackpanel {
    display: flex;
    flex-direction: row;
  }
  .textBlock {
    margin-left: 20px;
    margin-right: 20px;
  }
  .trenner {
    position: sticky;
    border: none;
    height: 1px;
    color: black;
    background: black;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 5px;
    margin-right: 5px;
    width: 690px;
  }
  .trenner-l {
    @extend .trenner;
    height: 2px !important;
    margin-left: -20px !important;
    margin-right: -20px !important;
    width: 740px;
  }
  .trenner-m {
    @extend .trenner;
    height: 1.5px !important;
    margin-left: -10px !important;
    margin-right: -10px !important;
    width: 720px;
  }
  .icon {
    margin-right: 20px;
    display: flex;
    padding-top: -20px;
  }
  img {
    border-radius: 5px;
  }
  .fragenBlock {
    height: auto;
    max-height: 240px;
    overflow: hidden;
  }
</style>